import React, { FC, useEffect } from "react";
import s from "./WTACCPayment.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallV2Payment } from "./WTAPaywallV2Payment";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { SlIcon } from "@sololearnorg/icons";
import { WTAPaywallV2Discount } from "./WTAPaywallV2Discount";
import { CheckoutStore } from "../../../checkout.store";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { Container } from "../../../../../symphony";
import { CheckoutService } from "../../../services/checkout.service";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { PaywallClickTypes } from "../../../paywalls.constants";

type Props = {
  data: null;
  sectionOrder: number;
};

export const WTACCPayment: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);

  const { closeCCCheckout } = Container.take('paywalls', CheckoutService);
  const { ccCheckoutOpen } = useContainerData('paywalls', CheckoutStore, ['ccCheckoutOpen']);
  const { trackSectionView, trackClick } = Container.take('paywalls', PaywallsTrackingService);

  useEffect(() => {
    if (ccCheckoutOpen) {
      trackSectionView('w2a-cc-checkout', sectionOrder);
    }
  }, [ccCheckoutOpen]);

  const handleClose = () => {
    trackClick(PaywallClickTypes.close, 'cc-checkout', 'CHECKOUT');
    closeCCCheckout();
  };

  return <div>
    <div className={`wta-cc-payment ${ccCheckoutOpen ? 'visible' : 'hidden'}`}>
      <div className={`wta-cc-payment-container ${ccCheckoutOpen ? 'visible' : 'hidden'}`}>
        <div className="wta-cc-payment-header">
          <div className="wta-cc-payment-close" onClick={handleClose}>
            <SlIcon iconId={'close'} />
          </div>
          <WTAPaywallV2Typography className="wta-cc-payment-title" level="h3" text="Checkout" />
        </div>
        <div className="wta-cc-payment-discount">
          <WTAPaywallV2Discount
            data={{ discount: 51, title: 'Your discount reserved for:', type: 'cc' }}
            sectionOrder={sectionOrder}
            disableTracking={true}
          />
        </div>
        <WTAPaywallV2Typography className="wta-cc-payment-description" level="h1" text="Pay safe & secure with your preferred payment method." />
        <WTAPaywallV2Payment
          data={data}
          sectionOrder={sectionOrder}
          disableTracking={true}
        />
      </div>
    </div>
    <div className={`wta-cc-payment-blur ${ccCheckoutOpen ? 'visible' : 'hidden'}`}></div>
  </div>;
};