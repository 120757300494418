import React, { FC, RefObject, useEffect } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WebToAppProducts.scss';
import { Container } from "../../../../symphony";
import { PaywallsService } from "../../services/paywalls.service";
import { useInView } from "../../paywalls.utils";
import { MoneyBackGuarantee, PaymentMethodIcons, ShieldIcon } from "../ui/icons";
import { useContainerData } from "../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../paywalls.store";
import { PaywallClickTypes } from "../../paywalls.constants";

type Props = {
  plans: any[];
  info: string;
  theme: 'light' | 'dark';
  onProductClick: (key: string, duration: number, theme: 'dark' | 'light') => void;
  selectedProductKey: string;
  onContinue: () => void;
  sectionOrder: number;
};

export const WebToAppProducts: FC<Props> = ({
  plans,
  info,
  theme,
  onProductClick,
  selectedProductKey,
  onContinue,
  sectionOrder
}) => {
  useStyles(s);
  const { trackSectionView, trackPaywallClick, onInteract } = Container.take('paywalls', PaywallsService);
  const { externalData } = useContainerData('paywalls', PaywallsStore, ['externalData']);
  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView('products', sectionOrder);
  }, [isInView]);

  return <div ref={ref as RefObject<HTMLDivElement>} className={`paywall-w2a-section paywall-w2a-plans ${theme}`}>
    <h2 className="paywall-w2a-section-title" style={{ marginBottom: '12px' }}>Choose your plan</h2>
    <div className="paywall-w2a-plans-list">
      {plans.map(p => (<div
        key={p.key}
        className={`paywall-w2a-plans-item ${selectedProductKey === p.key ? 'selected' : ''} ${theme} ${p.isMostPopular ? 'popular' : ''}`}
        onClick={() => {
          onInteract('product-click');
          onProductClick(p.key, p.duration, theme);
        }}
      >
        {p.isMostPopular && <div className="paywall-w2a-plans-most-popular">Most popular</div>}
        <div className={`paywall-w2a-plans-checkbox ${selectedProductKey === p.key ? 'selected' : ''}`}></div>
        <div className="paywall-w2a-plans-left-box">
          <span className={`paywall-w2a-plans-name ${p.key === 'free' ? 'small' : ''}`}>{p.name}</span>
          {p.key !== 'free' && <div className={`paywall-w2a-plans-sale-label ${selectedProductKey === p.key ? 'selected' : ''}`}>SAVE {p.discount}%</div>}
          {p.key !== 'free' && <div className="paywall-w2a-plans-prices">
            <span className="original">${p.originalPrice}</span>
            <span className="discounted">${p.discountedPrice}</span>
          </div>}
        </div>
        <div className={`paywall-w2a-plans-right-box ${selectedProductKey === p.key ? 'selected' : ''}`}>
          {p.key !== 'free' && <>
            <div className="paywall-w2a-plans-daily-prices">
              <span className="original">${(p.originalPrice / p.duration).toFixed(2)}</span>
              <span className="discounted"><span>$</span>{(p.discountedPrice / p.duration).toFixed(2)}</span>
            </div>
            <span className="paywall-w2a-plans-per-day">PER DAY</span>
          </>}
          {p.key === 'free' && <div className={`paywall-w2a-plans-free-label ${selectedProductKey === p.key ? 'selected' : ''}`}>free</div>}
        </div>
      </div>))}
    </div>
    {(externalData?.moneyBackProducts as string[])?.includes(selectedProductKey) && <div className={`paywall-w2a-plans-money-back ${theme}`}>
      <div className="paywall-w2a-plans-money-back-icon">
        <MoneyBackGuarantee />
      </div>
      <h6>30-Day Money-Back Guarantee</h6>
      <p>
        We believe that our lessons will make a difference for you.
        But if you don't see the value of Sololearn after completing different levels, we'll be ready to refund your money.
        Find more details in our <a
          onClick={() => trackPaywallClick(PaywallClickTypes.moneyBackGuaranteeLink)}
          href="/money-back-policy"
          target="_blank"
        >money-back policy</a>.</p>
    </div>}
    <div className={`paywall-w2a-plans-info ${theme}`}>
      {info}
    </div>
    <button className="paywall-w2a-plans-cta" onClick={() => {
      onInteract('continue');
      onContinue();
    }}>Continue</button>
    <div className="paywall-w2a-plans-safe-payment">
      <div><ShieldIcon /></div>
      <span>Pay safe & secure</span>
    </div>
    <div className="paywall-w2a-plans-payment-methods">
      <PaymentMethodIcons />
    </div>
  </div>;
};