import React, { FC } from "react";
import s from "./LongPaywallDiscountOffer.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { LongPaywallProductMeta } from "../../../../paywalls.constants";
import { Container, i18n } from "../../../../../../symphony";
import { SlIcon } from "@sololearnorg/icons";
import { useContainerData } from "../../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutService } from "../../../../services/checkout.service";
import { CheckoutStore } from "../../../../checkout.store";
import { PaywallsService } from "../../../../services/paywalls.service";
import { LongPaywallDiscountOfferBackground } from "./LongPaywallDiscountOfferBackground";

type Props = {
  data: {
    products: LongPaywallProductMeta[];
    title: string;
    description: string;
    mainCTA: string;
    secondaryCTA: string;
    footer: string;
  };
};

export const LongPaywallDiscountOffer: FC<Props> = ({ data }) => {
  useStyles(s);
  const { selectLongPaywallProduct } = Container.take('paywalls', CheckoutService);
  const { availableProducts } = useContainerData('paywalls', CheckoutStore, [
    'availableProducts'
  ]);
  const { onInteract } = Container.take('paywalls', PaywallsService);

  return availableProducts && <div className="long-paywall-discounted-offer">
    <LongPaywallDiscountOfferBackground />
    <div className="long-paywall-discounted-offer-header">
      <div className="long-paywall-discounted-offer-back" onClick={() => onInteract('back')}>
        <div className="long-paywall-discounted-offer-back-icon">
          <SlIcon iconId="backLeft" />
        </div>
        {i18n.t('web-paywall.long.discount-offer.button.back')}
      </div>
      <div className="long-paywall-discounted-offer-close" onClick={() => onInteract('close')}>
        <SlIcon iconId="close" />
      </div>
    </div>
    <h3 className="long-paywall-discounted-offer-title">{i18n.t(data.title)}</h3>
    <div className="long-paywall-discounted-offer-price">
      <span className="original">${availableProducts[0].product.amount}</span>
      <span className="discounted">${availableProducts[0].product.discountedAmount}</span>
      <span className="period">{
        availableProducts[0].product.period === 12
          ? i18n.t('web-paywall.long.discount-offer.period.year')
          : i18n.t('web-paywall.long.discount-offer.period.month', {
            period: availableProducts[0].product.period,
            count: availableProducts[0].product.period
          })
      }</span>
    </div>
    <p className="long-paywall-discounted-offer-description">{i18n.t(data.description)}</p>
    <div className="long-paywall-discounted-offer-buttons">
      <button
        className="long-paywall-discounted-offer-button primary"
        onClick={() => selectLongPaywallProduct(availableProducts[0].product.key)}
      >{i18n.t(data.mainCTA)}</button>
      <button
        className="long-paywall-discounted-offer-button secondary"
        onClick={() => onInteract('secondaryCTA')}
      >{i18n.t(data.secondaryCTA)}</button>
    </div>
    <span className="long-paywall-discounted-offer-footer">{i18n.t(data.footer, {
      discountedAmount: availableProducts[0].product.discountedAmount,
      originalAmount: availableProducts[0].product.amount
    })}</span>
  </div>;
};