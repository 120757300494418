import React, { FC, RefObject, useEffect, useState } from "react";
import s from "./WTAPaywallV2Discount.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { useInView } from "../../../paywalls.utils";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { Container } from "../../../../../symphony";
import { WTAPaywallV2Button } from "../components/WTAPaywallV2Button";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../../paywalls.store";
import { PaywallsService } from "../../../services/paywalls.service";
import { CheckoutService } from "../../../services/checkout.service";
import { PaywallClickTypes } from "../../../paywalls.constants";
import { WTAPayawllV2Card } from "../components/WTAPayawllV2Card";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { ClockIcon } from "../../ui/icons";

type Props = {
  data: {
    discount: number;
    title?: string;
    type?: 'primary' | 'secondary' | 'cc';
    trackingKey?: string;
  };
  sectionOrder: number;
  disableTracking?: boolean;
};

export const WTAPaywallV2Discount: FC<Props> = ({ data, sectionOrder, disableTracking = false }) => {
  useStyles(s);
  const { type, discount, title, trackingKey } = data;
  const { countdown } = useContainerData('paywalls', PaywallsStore, ['countdown']);
  const { countdownSetup, onInteract } = Container.take('paywalls', PaywallsService);
  const [numbers, setNumbers] = useState<number[]>([]);
  const { trackSectionView, trackClick } = Container.take('paywalls', PaywallsTrackingService);
  const { scrollToPlans } = Container.take('paywalls', CheckoutService);

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    const subscription = countdownSetup(600).subscribe();
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const hours = Math.floor(countdown / 3600);
    const minutes = Math.floor((countdown % 3600) / 60);
    const secs = countdown % 60;
    const pad = (num: number) => num.toString().padStart(2, '0');
    const padString = `${pad(hours)}${pad(minutes)}${pad(secs)}`;
    setNumbers(padString.split('').map(char => +char));
  }, [countdown]);

  useEffect(() => {
    if (isInView && !disableTracking) {
      trackSectionView(trackingKey || 'discount', sectionOrder);
    }
  }, [isInView]);

  const handleClick = () => {
    trackClick(PaywallClickTypes.cta, 'discount');
    onInteract('discount');
    scrollToPlans();
  };

  return <div className="wtapv2-discount" ref={ref as RefObject<HTMLDivElement>} >
    {title && type !== 'cc' && <WTAPaywallV2Typography level="h1" text={title} />}
    <WTAPayawllV2Card className={`wtapv2-discount-card ${type}`}>
      {type === 'primary' && <div className="wtapv2-discount-primary">
        <div className="wtapv2-discount-data">
          <span>Discount <span className="wtapv2-discount-data-discount">{discount}%</span> reserved for:</span>
          <div className="wtapv2-discount-data-timer">
            <span>{numbers[2]}</span>
            <span>{numbers[3]}</span>
            <span>:</span>
            <span>{numbers[4]}</span>
            <span>{numbers[5]}</span>
          </div>
        </div>
        <WTAPaywallV2Button
          onClick={handleClick}
          styles={{
            height: '42px',
            fontSize: '12px',
            width: 'auto',
            padding: '0 16px'
          }}
          text={"Get My Plan"}
        />
      </div>}
      {type === 'secondary' && <div className="wtapv2-discount-secondary">
        <div className="wtapv2-discount-secondary-row">
          <WTAPaywallV2Typography level="h3" text="ONE TIME OFFER:" />
          <WTAPaywallV2Typography
            level="h2"
            text={`${discount}% off`}
            className="wtapv2-discount-secondary-row-box"
          />
        </div>
        <div className="wtapv2-discount-secondary-divider"></div>
        <div className="wtapv2-discount-secondary-row">
          <WTAPaywallV2Typography level="h3" text="Discount reserved for:" />
          <div className="wtapv2-discount-data-timer secondary">
            <span>{numbers[2]}</span>
            <span>{numbers[3]}</span>
            <span>:</span>
            <span>{numbers[4]}</span>
            <span>{numbers[5]}</span>
          </div>
        </div>
      </div>}
      {type === 'cc' && <div className="wtapv2-discount-cc">
        <ClockIcon />
        <WTAPaywallV2Typography className="wtapv2-discount-cc-label" level="caption" text={`${title} ${numbers[2]}${numbers[3]}:${numbers[4]}${numbers[5]}`} />
      </div>}
    </WTAPayawllV2Card>
  </div>;
};
