import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PaywallHeader.scss';
import { SlIcon } from "@sololearnorg/icons";
import { Container } from "../../../../../symphony";
import { PaywallsService } from "../../../services/paywalls.service";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../../paywalls.store";
import { Touchpoint } from "../../../../../api/public/subscription-api";

type Props = {
  version?: 1 | 2;
};
export const PaywallHeader: FC<Props> = ({ version = 1 }) => {
  useStyles(s);

  const { closePaywall, closeCheckout } = Container.take('paywalls', PaywallsService);
  const { currentTouchpoint } = useContainerData('paywalls', PaywallsStore, ['currentTouchpoint']);

  const logo = <div className="paywalls-logo">
    <SlIcon iconId="sololearnMono" />
  </div>;
  const closeButton = <div className="paywalls-close" onClick={() => closePaywall()}>
    <SlIcon iconId="close" />
  </div>;
  const proIcon = <div className="paywalls-pro-icon">PRO</div>;
  const backIcon = <div className="paywalls-close" onClick={() => closeCheckout()}>
    <SlIcon iconId="close" />
  </div>;

  const getComponentsByVersion = () => {
    if (currentTouchpoint === Touchpoint.web2app) {
      return <>{logo}{backIcon}</>;
    }
    switch (version) {
      case 1:
        return <>{logo}{closeButton}</>;
      case 2:
        return <>{closeButton}{proIcon}</>;
    }
  };

  return <div className="paywalls-header">
    {getComponentsByVersion()}
  </div>;
};