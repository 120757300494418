import { Container, Service, TrackingEventNames, TrackingService } from "../../../symphony";
import { PaywallClickTypes, PaywallNavigationPage } from "../paywalls.constants";
import { PaywallsStore } from "../paywalls.store";

@Service()
export class PaywallsTrackingService {
  private trackingSerivce = Container.take('global', TrackingService);
  private store = Container.take('paywalls', PaywallsStore);

  public trackImpression = (webPageType?: 'PAYWALL' | 'CHECKOUT') => {
    const touchpoint = this.store.currentTouchpoint.value;
    const { key, resolvedVariables } = this.store.paywallData.value;
    const { page, step } = this.store.paywallNavigation.value;
    this.trackingSerivce.tracking$.next({
      eventName: TrackingEventNames.paywallImpression,
      eventParams: {
        touchpoint,
        webPageType: webPageType || (page === PaywallNavigationPage.paywall ? 'PAYWALL' : 'CHECKOUT'),
        orderNumber: step,
        resolvedVariables: resolvedVariables || {},
        paywallKey: key,
        version: '3-0-0',
        appVersion: '0.0.0'
      },
      immediate: true
    });
  };

  public trackClick = (clickType: PaywallClickTypes, value?: string, webPageType?: 'PAYWALL' | 'CHECKOUT') => {
    const touchpoint = this.store.currentTouchpoint.value;
    const { key, resolvedVariables } = this.store.paywallData.value;
    const { page, step } = this.store.paywallNavigation.value;
    this.trackingSerivce.tracking$.next({
      eventName: TrackingEventNames.paywallClick,
      eventParams: {
        value: value || null,
        touchpoint,
        clickType,
        webPageType: webPageType || (page === PaywallNavigationPage.paywall ? 'PAYWALL' : 'CHECKOUT'),
        orderNumber: step,
        resolvedVariables: resolvedVariables || {},
        paywallKey: key,
        version: '3-0-0',
        appVersion: '0.0.0'
      },
      immediate: true
    });
  };

  public trackSectionView = (section: string, sectionOrder: number) => {
    const touchpoint = this.store.currentTouchpoint.value;
    const { key, resolvedVariables } = this.store.paywallData.value;
    const { page, step } = this.store.paywallNavigation.value;
    this.trackingSerivce.tracking$.next({
      eventName: TrackingEventNames.paywallSectionView,
      eventParams: {
        touchpoint,
        webPageType: page === PaywallNavigationPage.paywall ? 'PAYWALL' : 'CHECKOUT',
        orderNumber: step,
        resolvedVariables: resolvedVariables || {},
        paywallKey: key,
        version: '1-0-0',
        appVersion: '0.0.0',
        section,
        sectionOrder: sectionOrder.toString()
      },
      immediate: true
    });
  };
}
