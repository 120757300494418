import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import {
  Container,
  Service,
  TrackingEventNames,
  TrackingService,
  MetaTrackingService
} from '../../../symphony';
import { SubscriptionPlanId } from '../../../shared/public/globalInterfaces/globalInterfaces';
import {
  IGatewayTokenResponse, IPaymentConfirmPayload, IPaymentConfirmationResponse, IPaymentPlanQuery, IPaymentPlanResponse, PaymentApi,
} from '../../../api/public/payment-api';
import { ExperimentApi, IDynamicProPageExperimentResponse } from '../../../api/public/experiment-api';
import { SlApiContext } from '../../../api/private/api-context';
import { SlErrorHandlerService } from '../../../api/public/error-handling';

export interface ISubscriptionHistory {
  cancelDate: string;
  paymentPlanId: SubscriptionPlanId;
  subscriptionDate: string;
  trialExpiredDate: string;
}

@Service()
export class SlPaymentApiService {
  private paymentApi = Container.take('global', PaymentApi);

  private experimentApi = Container.take('global', ExperimentApi);

  private gatewayTokens: Observable<IGatewayTokenResponse>;

  private paymentPlans: Observable<IPaymentPlanResponse>;

  private tracking$ = Container.take('global', TrackingService).tracking$;

  private metaTrackingService = Container.take('global', MetaTrackingService);

  private handleErrorCatching = Container.take(
    SlApiContext,
    SlErrorHandlerService,
  ).handleErrorCatching;

  private paymentPlan = {};

  public gatewayTokenStream(): Observable<IGatewayTokenResponse> {
    if (!this.gatewayTokens) {
      this.gatewayTokens = this.paymentApi
        .getGatewayToken()
        .pipe(shareReplay(4), this.handleErrorCatching(true, {}, null));
    }
    return this.gatewayTokens;
  }

  public getPaymentPlans(): Observable<IPaymentPlanResponse> {
    if (!this.paymentPlans) {
      this.paymentPlans = this.paymentApi
        .getPaymentPlans()
        .pipe(shareReplay({ refCount: true }));
    }
    return this.paymentPlans;
  }

  public getPaymentPlan(
    id: string,
    query?: IPaymentPlanQuery,
  ): Observable<IPaymentPlanResponse> {
    if (!this.paymentPlan[id]) {
      this.paymentPlan[id] = this.paymentApi
        .getPaymentPlan(id, query)
        .pipe(shareReplay({ refCount: true }));
    }
    return this.paymentPlan[id];
  }

  public subscribe(
    payload: IPaymentConfirmPayload,
  ): Observable<IPaymentConfirmationResponse> {
    return this.paymentApi.subscribe(payload).pipe(
      tap(() => {
        const { product } = payload;
        if (product.trialDays > 0) {
          this.metaTrackingService.trackTrialStarted(product);
        } else {
          this.metaTrackingService.trackPurchase(product);
        }
      }),
    );
  }

  public getDynamicProPageExperimentData(): Observable<IDynamicProPageExperimentResponse> {
    return this.experimentApi.getDynamicProPageExperimentData();
  }
}
