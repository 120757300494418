import { DefaultCodeLanguages } from '../../../api/public/code-api';

export interface IFeatureToggle {
  state: 'enabled' | 'disabled' | FeatureState;
  config?: any;
}

export enum FeatureState {
  enabled = 'enabled',
  disabled = 'disabled',
}

export enum SlMicroFrontends {
  mfProfile = 'mf-profile',
  mfLearn = 'mf-learn',
  mfCodeCoach = 'mf-code-coach',
  mfCodeRepo = 'mf-code-repo',
  mfNavigation = 'mf-navigation',
  mfOnboarding = 'mf-onboarding',
  mfSSRContainer = 'mf-ssr-container',
  mfHome = 'mf-home',
  mfPayments = 'mf-payments',
  mfLogin = 'mf-login',
  mfSymphony = 'mf-symphony',
}

export enum SlFeatureToggle {
  welcomeBack = 'welcome-back',
  freeClickCC = 'free-clickCC',
  startPrompt = 'startPrompt',
  quitPrompt = 'quitPrompt',
  leaderBoard = 'leaderBoard',
  codeRepo = 'codeRepo',
  codeCoachSolution = 'codeCoachSolution',
  landToLessonExperiment = 'landToLessonExperiment',
  lessonNewCCLogic = 'lessonNewCCLogic',
  highlightFirstCCExperiment = 'highlightFirstCCExperiment',
  homepageHeaderSignupModalExperiment = 'homepageHeaderSignupModalExperiment',
  dailyDose = 'dailyDose',
  freeCodeReposExperiment = 'freeCodeReposExperiment',
  surveyExperiment = 'surveyExperiment',
  ccSolutionGamificationExperiment = 'ccSolutionGamificationExperiment',
  CRProgressHintExperiment = 'CRProgressHintExperiment',
  userGuidanceExperiment = 'userGuidanceExperiment',
  completeCelebrationExperiment = 'completeCelebrationExperiment',
  ccCommentsExperiment = 'ccCommentsExperiment',
  crCommentsExperiment = 'crCommentsExperiment',
  codeRepoInLesson = 'codeRepoInLesson',
  homepageSocialSignUpExperiment = 'homepageSocialSignUpExperiment',
  ccMandatoryExperiment = 'ccMandatoryExperiment',
  lessonProgressBarRedesignExperiment = 'lessonProgressBarRedesignExperiment',
  nonLoggedInProPage = 'nonLoggedInProPage',
  quizBackToTheoryExperiment = 'quizBackToTheoryExperiment',
  recaptcha = 'recaptcha',
  publicTokenCaptcha = 'publicTokenCaptcha',
  appsFlyer = 'appsFlyer',
  thermOfConditionsModal = 'thermOfConditionModal',
  privacyPolicyModal = 'privacyPolicyModal',
  iterableTracking = 'iterableTracking',
  seriousLearner = 'seriousLearner',
  experimentalCoursesExperiment = 'experimentalCoursesExperiment',
  betaTestLearnEngine = 'betaTestLearnEngine',
}

export interface IFeatureTogglingConfigs {
  [SlMicroFrontends.mfProfile]?: {
    [SlFeatureToggle.welcomeBack]?: IFeatureToggle;
    [SlFeatureToggle.freeClickCC]?: IFeatureToggle;
    [SlFeatureToggle.dailyDose]?: IFeatureToggle;
    [SlFeatureToggle.experimentalCoursesExperiment]?: IFeatureToggle;
  };
  [SlMicroFrontends.mfLearn]?: {
    [SlFeatureToggle.welcomeBack]?: IFeatureToggle;
    [SlFeatureToggle.codeRepo]?: IFeatureToggle;
    [SlFeatureToggle.landToLessonExperiment]?: IFeatureToggle;
    [SlFeatureToggle.lessonNewCCLogic]?: IFeatureToggle;
    [SlFeatureToggle.highlightFirstCCExperiment]?: IFeatureToggle;
    [SlFeatureToggle.dailyDose]?: IFeatureToggle;
    [SlFeatureToggle.freeCodeReposExperiment]?: IFeatureToggle;
    [SlFeatureToggle.CRProgressHintExperiment]?: IFeatureToggle;
    [SlFeatureToggle.userGuidanceExperiment]?: IFeatureToggle;
    [SlFeatureToggle.completeCelebrationExperiment]?: IFeatureToggle;
    [SlFeatureToggle.codeRepoInLesson]?: IFeatureToggle;
    [SlFeatureToggle.freeClickCC]?: IFeatureToggle;
    [SlFeatureToggle.ccMandatoryExperiment]?: IFeatureToggle;
    [SlFeatureToggle.lessonProgressBarRedesignExperiment]?: IFeatureToggle;
    [SlFeatureToggle.quizBackToTheoryExperiment]?: IFeatureToggle;
    [SlFeatureToggle.experimentalCoursesExperiment]?: IFeatureToggle;
  };
  [SlMicroFrontends.mfCodeCoach]?: {
    [SlFeatureToggle.startPrompt]?: IFeatureToggle;
    [SlFeatureToggle.quitPrompt]?: IFeatureToggle;
    [SlFeatureToggle.codeCoachSolution]?: IFeatureToggle;
    [SlFeatureToggle.ccSolutionGamificationExperiment]?: IFeatureToggle;
    [SlFeatureToggle.ccCommentsExperiment]?: IFeatureToggle;
    [SlFeatureToggle.completeCelebrationExperiment]?: IFeatureToggle;
  };
  [SlMicroFrontends.mfCodeRepo]?: {
    [SlFeatureToggle.crCommentsExperiment]?: IFeatureToggle;
  };
  [SlMicroFrontends.mfPayments]?: {
    [SlFeatureToggle.nonLoggedInProPage]?: IFeatureToggle;
    [SlFeatureToggle.seriousLearner]?: IFeatureToggle;
  };
  [SlMicroFrontends.mfNavigation]?: {
    [SlFeatureToggle.homepageHeaderSignupModalExperiment]?: IFeatureToggle;
    [SlFeatureToggle.nonLoggedInProPage]?: IFeatureToggle;
  };
  [SlMicroFrontends.mfOnboarding]?: {
    [SlFeatureToggle.dailyDose]?: IFeatureToggle;
    [SlFeatureToggle.surveyExperiment]?: IFeatureToggle;
  };
  [SlMicroFrontends.mfSSRContainer]?: {
    [SlFeatureToggle.leaderBoard]?: IFeatureToggle;
    [SlFeatureToggle.appsFlyer]?: IFeatureToggle;
    [SlFeatureToggle.privacyPolicyModal]?: IFeatureToggle;
    [SlFeatureToggle.thermOfConditionsModal]?: IFeatureToggle;
  };
  [SlMicroFrontends.mfHome]?: {
    [SlFeatureToggle.homepageHeaderSignupModalExperiment]?: IFeatureToggle;
    [SlFeatureToggle.homepageSocialSignUpExperiment]?: IFeatureToggle;
    [SlFeatureToggle.nonLoggedInProPage]?: IFeatureToggle;
  };
  [SlMicroFrontends.mfLogin]?: {
    [SlFeatureToggle.recaptcha]?: IFeatureToggle;
    [SlFeatureToggle.publicTokenCaptcha]?: IFeatureToggle;
    [SlFeatureToggle.betaTestLearnEngine]?: IFeatureToggle;
  };
  [SlMicroFrontends.mfSymphony]?: {
    [SlFeatureToggle.iterableTracking]?: IFeatureToggle;
  };
}

export enum SlTooltipPositions {
  top = 'top',
  topLeft = 'top-left',
  topRight = 'top-right',
  bottom = 'bottom',
  bottomRight = 'bottom-right',
  bottomLeft = 'bottom-left',
}

export enum SlTooltipSizes {
  small = 'small',
  medium = 'medium',
  long = 'long',
}

export enum SlNavbarThemes {
  light = 'light',
  dark = 'dark',
}

export enum SlNavBarPositions {
  fixed = 'fixed',
  relative = 'relative',
  sticky = 'sticky',
}

export enum SlNavbarType {
  default,
  teams,
}

export interface ISlNavigationService {
  show: () => void;
  hide: () => void;
  setInitialConfigs: (configs: ISlNavbarInitialConfigs) => void;
  setTheme: (theme: SlNavbarThemes) => void;
  setType: (type: SlNavbarType) => void;
}

export interface ISlFooterService {
  show: () => void;
  hide: () => void;
}

export interface ILogOutConfig {
  pathname: string;
  type: 'external' | 'internal';
  callback?: () => void;
}

export interface ISlNavbarInitialConfigs {
  navbarPosition?: SlNavBarPositions;
  logoutConfig?: ILogOutConfig;
}

export enum SlIconsEnum {
  /* eslint-disable camelcase */
  educational_blue = 'educationalBlue',
}

export enum SlStorageKeys {
  hardBlocker = 'hardBlocker',
  Sl_LE_SESSION = 'sl-le-session',
}

export interface ISlStorageHardBlocker {
  termOfConditions: string;
  privacyPolicy: string;
}

export enum SubscriptionPlanId {
  monthly = 8,
  yearly = 7,
  yearlyWithoutTrial = 38,
  yearlyWithOffer = 39,
  group = 40,
  basic = -1,
}

export enum AppStore {
  AppleStore = 'apple store',
  PlayStore = 'play store',
}

export enum LanguageRoutes {
  WEB = 'web',
  CPP = 'cpp',
  C = 'c',
  PHP = 'php',
  JAVA = 'java',
  PYTHON = 'python',
  SWIFT = 'swift',
  CSharp = 'csharp',
  RUBY = 'ruby',
  NODEJS = 'nodejs',
  KOTLIN = 'kotlin',
  JQUERY = 'jquery',
  JAVASCRIPT = 'javascript',
  CSS = 'css',
  HTML = 'html',
  SQL = 'sql',
  GO = 'go',
  R = 'r',
  TS = 'typescript',
}

export const MappingRouteLanguageToDefaultCodeGetting = {
  [LanguageRoutes.JQUERY]: {
    defaultCodeApi: DefaultCodeLanguages.JQUERY,
  },
  [LanguageRoutes.WEB]: {
    defaultCodeApi: DefaultCodeLanguages.WEB,
  },
  [LanguageRoutes.HTML]: {
    defaultCodeApi: DefaultCodeLanguages.WEB,
  },
  [LanguageRoutes.CSS]: {
    defaultCodeApi: DefaultCodeLanguages.WEB,
  },
  [LanguageRoutes.JAVASCRIPT]: {
    defaultCodeApi: DefaultCodeLanguages.WEB,
  },
  [LanguageRoutes.CPP]: {
    defaultCodeApi: DefaultCodeLanguages.CPP,
  },
  [LanguageRoutes.C]: {
    defaultCodeApi: DefaultCodeLanguages.C,
  },
  [LanguageRoutes.PHP]: {
    defaultCodeApi: DefaultCodeLanguages.PHP,
  },
  [LanguageRoutes.JAVA]: {
    defaultCodeApi: DefaultCodeLanguages.JAVA,
  },
  [LanguageRoutes.PYTHON]: {
    defaultCodeApi: DefaultCodeLanguages.PYTHON,
  },
  [LanguageRoutes.SWIFT]: {
    defaultCodeApi: DefaultCodeLanguages.SWIFT,
  },
  [LanguageRoutes.CSharp]: {
    defaultCodeApi: DefaultCodeLanguages.CS,
  },
  [LanguageRoutes.RUBY]: {
    defaultCodeApi: DefaultCodeLanguages.RUBY,
  },
  [LanguageRoutes.KOTLIN]: {
    defaultCodeApi: DefaultCodeLanguages.KOTLIN,
  },
  [LanguageRoutes.NODEJS]: {
    defaultCodeApi: DefaultCodeLanguages.NODEJS,
  },
  [LanguageRoutes.GO]: {
    defaultCodeApi: DefaultCodeLanguages.GO,
  },
  [LanguageRoutes.R]: {
    defaultCodeApi: DefaultCodeLanguages.R,
  },
  [LanguageRoutes.TS]: {
    defaultCodeApi: DefaultCodeLanguages.TS,
  },
};

export enum LanguageNames {
  WEB = 'HTML/CSS/JavaScript',
  HTML = 'HTML',
  CSS = 'CSS',
  JAVASCRIPT = 'Javascript',
  CPP = 'C++',
  C = 'C',
  PHP = 'PHP',
  JAVA = 'Java',
  PYTHON = 'Python',
  SWIFT = 'Swift',
  CSharp = 'C#',
  RUBY = 'Ruby',
  NODEJS = 'Node.JS',
  KOTLIN = 'Kotlin',
  JQUERY = 'jQuery',
  SQL = 'SQL',
  GO = 'Go',
  R = 'R',
  TS = 'TypeScript',
}

export interface IMessageDrawerOptions {
  enableJumpLink?: boolean;
  enableLinkMessage?: boolean;
  enableUserMention?: boolean;
}
