import React, { FC, useEffect, RefObject } from "react";
import s from "./WTACCTrySololearn.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { SlIcon } from "@sololearnorg/icons";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../../paywalls.store";

type Props = {
  data: {
    title: string;
    items: string[];
    goal: {
      label: string;
      value: string;
    };
    target: {
      label: string;
      value: string;
    };
  };
  sectionOrder: number;
};

export const WTACCTrySololearn: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { title, items, goal, target } = data;

  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);
  const { externalData } = useContainerData('paywalls', PaywallsStore, ['externalData']);

  const replaceVariable = (value: string) => {
    return value.replace(/\{\{(.*?)\}\}/g, (match, key) => externalData?.wtaAnswers ? (externalData.wtaAnswers[key] || match) : match);
  };

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView('w2a-cc-try-sololearn', sectionOrder);
  }, [isInView]);

  return <div className="wta-cc-try-sololearn" ref={ref as RefObject<HTMLDivElement>}>
    <WTAPaywallV2Typography className="wta-cc-try-sololearn-title" level="h1" text={title} />
    <ul className="wta-cc-try-sololearn-items">
      {items.map((item, index) => (
        <li key={index} className="wta-cc-try-sololearn-item">
          <div className="wta-cc-try-sololearn-item-icon">
            <SlIcon iconId={'checkmarkFilled'} />
          </div>
          <WTAPaywallV2Typography level="paragraph" text={item} />
        </li>
      ))}
    </ul>
    <div className="wta-cc-try-sololearn-footer">
      <div className="wta-cc-try-sololearn-footer-item">
        <span className="wta-cc-try-sololearn-footer-item-label">{goal.label}</span>
        <span className="wta-cc-try-sololearn-footer-item-value">{replaceVariable(goal.value)}</span>
      </div>
      <div className="wta-cc-try-sololearn-footer-item">
        <span className="wta-cc-try-sololearn-footer-item-label">{target.label}</span>
        <span className="wta-cc-try-sololearn-footer-item-value">{replaceVariable(target.value)}</span>
      </div>
    </div>
  </div>;
};
