import React, { FC } from "react";
import s from "./LongPaywalllMinimalisticProducts.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { LongPaywallProductMeta, LongPaywallProductNames, PaywallClickTypes } from "../../../../paywalls.constants";
import { Container, i18n } from "../../../../../../symphony";
import { PaywallsService } from "../../../../services/paywalls.service";
import { CheckoutService } from "../../../../services/checkout.service";
import { useContainerData } from "../../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../../checkout.store";
import { MaxIcon, ProIcon } from "../../../ui/icons";

type Props = {
  data: {
    products: LongPaywallProductMeta[];
  };
};

export const LongPaywalllMinimalisticProducts: FC<Props> = ({ data }) => {
  useStyles(s);
  const { trackPaywallClick } = Container.take('paywalls', PaywallsService);
  const { selectLongPaywallProduct } = Container.take('paywalls', CheckoutService);
  const { availableProducts } = useContainerData('paywalls', CheckoutStore, [
    'availableProducts'
  ]);

  const productName = (name: LongPaywallProductNames) => {
    const billingCycle = {
      [LongPaywallProductNames.proMonthlyNoTrial]: 'web-paywall.long.product-period.monthly',
      [LongPaywallProductNames.proAnnualNoTrial]: 'web-paywall.long.product-period.annual',
      [LongPaywallProductNames.proAnnualTrial]: 'web-paywall.long.product-period.annual',
      [LongPaywallProductNames.maxAnnualNoTrial]: 'web-paywall.long.product-period.annual',
      [LongPaywallProductNames.maxAnnualTrial]: 'web-paywall.long.product-period.annual'
    };

    const type = {
      [LongPaywallProductNames.proMonthlyNoTrial]: "PRO",
      [LongPaywallProductNames.proAnnualNoTrial]: "PRO",
      [LongPaywallProductNames.proAnnualTrial]: "PRO",
      [LongPaywallProductNames.maxAnnualNoTrial]: "MAX",
      [LongPaywallProductNames.maxAnnualTrial]: "MAX"
    };

    return <div className="minimalistic-products-name">
      <span>{type[name]} {i18n.t(billingCycle[name])}</span>
    </div>;
  };

  const onButtonClick = (productKey: string) => {
    trackPaywallClick(PaywallClickTypes.cta, productKey);
    selectLongPaywallProduct(productKey);
  };

  return availableProducts ? <div className="minimalistic-products">
    {availableProducts.map((p) => (
      <div className="minimalistic-products-item" key={p.product.key}>
        {p.meta.tag && <div className="minimalistic-products-tag">{i18n.t(p.meta.tag)}</div>}
        <div className="minimalistic-products-header">
          {productName(p.meta.name)}
          <span className="minimalistic-products-price">${p.product.discountedAmount}/{i18n.t(p.meta.priceSuffix)}</span>
        </div>
        <span className="minimalistic-products-billing-cycle" dangerouslySetInnerHTML={{ __html: i18n.t(p.meta.billingInfo) }}></span>
        <button className="minimalistic-products-cta" onClick={() => onButtonClick(p.product.key)}>{i18n.t(p.meta.cta)}</button>
      </div>
    ))}
  </div> : null;
};