import { Observable, shareReplay, switchMap, tap } from 'rxjs';

import {
  Container,
  Service,
  ILearningExperience,
  LearningExperienceType,
  EnrollmentStatus,
  UserTrackService,
  CourseTrack
} from '../../../symphony';

import {
  ILearnEngineApi,
  ISolveMaterialPayload,
  ILearnEngineApiVersions,
  ISolveMaterialDTO,
  ICodeRunPayload,
  IMaterialSolutionDTO,
  IEnrollCoursePayload,
  ICourseStructureDTO,
  ScreenSource,
  IScreenConfig,
  ICategorizedCourse,
  LessonSessionQuitType,
  CodeAssistantConfigType,
  IMaterialInfo,
  ILessonSubtree,
  IMaterial,
  ILearningPathSubtree,
  ILEHeaders,
  IModelConfig,
  ICodeCompilePayload,
  IAnswerOption,
} from '../../private/learn-engine-api/learn-engine-api.interface';
import {
  AxiosInstanceType,
  SlAxiosInstanceService,
} from '../../private/services/sl-axios-instance.service';
import { IApiUrls, IHostsConfigs } from '../global';
import { SlApiContext } from '../../private/api-context';
import { AuthLevel, Authorize } from '../../private/authorize.decorator';
import {
  constructUrl,
  constructConfig,
} from '../../private/utils/httpParamsUtils';
import { Message } from '../../../aIChatPlayground/private/aIChatPlaygroundInterface';
import { SlFetchInstanceService } from '../../private/services/sl-fetch-instance.service';
import { getLEHeaders } from '../../../learnEngine/private/utils/utilsFunctions';

@Service()
export class LearnEngineApi implements ILearnEngineApi {
  private environmentUrl: string;

  private versions: ILearnEngineApiVersions;

  private axiosInstance: AxiosInstanceType;

  private learningExperiences$: Observable<ILearningExperience[]>;

  private getLearningExpsInProgress: boolean = false;

  private userTrackService = Container.take('global', UserTrackService);

  private fetchService: SlFetchInstanceService;


  constructor() {
    this.environmentUrl = (
      Container.take('global', 'envUrl') as IApiUrls
    )?.learnEngineApiHost;

    this.versions = (
      Container.take('global', 'hostsConfigs') as IHostsConfigs
    )?.learnEngineApi?.version;

    this.axiosInstance = Container.take(
      SlApiContext,
      SlAxiosInstanceService,
    ).axios;

    this.fetchService = Container.take(
      SlApiContext,
      SlFetchInstanceService
    );
  }

  @Authorize(AuthLevel.authenticated)
  public getMaterial(
    materialRelationId: number,
    session?: string,
  ): Observable<IMaterialInfo> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, 'api/learn/usermaterial'),
      {
        ...constructConfig({
          version: this.versions?.getMaterial,
          session,
        }),
        params: {
          materialRelationId,
        },
      },
    );
  }

  @Authorize(AuthLevel.authenticated)
  public getLessonSubtree(
    materialRelationId: number,
    session?: string,
    headers?: ILEHeaders,
  ): Observable<ILessonSubtree> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, 'api/learn/lessonsubtree'),
      {
        params: {
          materialRelationId,
        },
        ...constructConfig({
          version: this.versions?.getLessonSubtree,
          session,
          others: { ...headers },
        }),
      },
    );
  }

  @Authorize(AuthLevel.authenticated)
  public getSkipAheadSubTree(
    materialRelationId: number,
    session?: string,
    headers?: ILEHeaders,
  ): Observable<ILessonSubtree> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, '/api/learn/skipAhead'),
      {
        params: {
          materialRelationId,
        },
        ...constructConfig({
          version: this.versions?.getLessonSubtree,
          session,
          isDynamicSession: true,
          others: { ...headers },
        }),
      },
    );
  }

  @Authorize(AuthLevel.authenticated)
  public getCourseSubtree(alias: string): Observable<IMaterial> {
    return this.axiosInstance.get<IMaterial>(
      constructUrl(this.environmentUrl, 'api/learn/coursesubtree'),
      {
        params: {
          alias,
        },
        ...constructConfig({
          version: this.versions?.getCourseSubtree,
        }),
      },
    );
  }

  @Authorize(AuthLevel.authenticated)
  public getLearningPathSubtree(
    alias: string,
  ): Observable<ILearningPathSubtree> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, 'api/learn/pathsubtree'),
      {
        params: {
          alias,
        },
        ...constructConfig({
          version: this.versions?.getLearningPathSubtree,
        }),
      },
    );
  }

  @Authorize(AuthLevel.public)
  public getLearningExperiences(
    useCache?: boolean,
  ): Observable<ILearningExperience[]> {
    if ((useCache || this.getLearningExpsInProgress) && this.learningExperiences$) {
      return this.learningExperiences$;
    }
    this.getLearningExpsInProgress = true;
    this.learningExperiences$ = this.axiosInstance
      .get(
        constructUrl(
          this.environmentUrl,
          'api/learn/learningexperience',
        ),
      ).pipe(
        shareReplay({ refCount: false, bufferSize: 1 }),
        tap((exps: ILearningExperience[]) => {
          this.getLearningExpsInProgress = false;
          const enrolledExps = exps.filter(exp => exp.enrollmentStatusId === EnrollmentStatus.Enrolled);
          const last = enrolledExps.reduce((latest, current) => {
            return new Date(current.lastActivityDate) > new Date(latest.lastActivityDate) ? current : latest;
          }, enrolledExps[0]);
          this.userTrackService.setUserTrack(last ? last.track : this.userTrackService.getUserTrack() || CourseTrack.Codding);
        })
      ) as Observable<ILearningExperience[]>;

    return this.learningExperiences$;
  }

  @Authorize(AuthLevel.public)
  public getLearningExperiencesById(
    userId: number,
  ): Observable<ILearningExperience[]> {
    return this.axiosInstance.get(
      constructUrl(
        this.environmentUrl,
        `api/learn/learningexperience/${userId}`,
      ),
    );
  }

  @Authorize(AuthLevel.authenticated)
  public solveMaterial(
    payload: ISolveMaterialPayload,
    session?: string,
    headers?: ILEHeaders,
    isDynamicSession?: boolean,
  ): Observable<ISolveMaterialDTO> {
    return this.axiosInstance.post(
      constructUrl(this.environmentUrl, 'api/learn/solve'),
      payload,
      constructConfig({
        version: this.versions?.solveMaterial,
        session,
        isDynamicSession,
        others: { ...headers },
      }),
    );
  }

  @Authorize(AuthLevel.authenticated)
  public regenerateAIChatPlaygroundLastMessage(
    materialRelationId: number,
    conversationId: string,
    configs: IModelConfig[],
    session?: string,
  ): Observable<Message> {
    return this.axiosInstance.put(
      constructUrl(this.environmentUrl, `api/conversation/regenerate/${materialRelationId}`),
      {
        configs,
        conversationId,
      },
      constructConfig({
        session,
      }),
    );
  }

  @Authorize(AuthLevel.public)
  public getCategories(): Observable<ICategorizedCourse[]> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, 'api/learn/categories'),
      constructConfig({
        version: this.versions?.getCategories,
      }),
    );
  }

  @Authorize(AuthLevel.authenticated)
  public enrollCourse(payload: IEnrollCoursePayload): Observable<any> {
    return this.axiosInstance.post(
      constructUrl(this.environmentUrl, 'api/learn/enroll'),
      payload,
      constructConfig({
        version: this.versions?.enrollCourse,
      }),
    ).pipe(
      switchMap(() => this.getLearningExperiences())
    );
  }

  @Authorize(AuthLevel.authenticated)
  public addBetaTester(): Observable<null> {
    return this.axiosInstance.post(
      constructUrl(this.environmentUrl, 'api/betaTester'),
      {},
      constructConfig({
        version: this.versions?.addBetaTester,
      }),
    );
  }

  @Authorize(AuthLevel.authenticated)
  public runCode(payload: ICodeRunPayload): Observable<null> {
    return this.axiosInstance.post(
      constructUrl(this.environmentUrl, 'api/code/run'),
      payload,
      constructConfig({
        version: this.versions?.runCode,
      }),
    );
  }

  @Authorize(AuthLevel.authenticated)
  public compileCode(payload: ICodeCompilePayload): Observable<null> {
    return this.axiosInstance.post(
      constructUrl(this.environmentUrl, 'api/code/compile'),
      payload,
      constructConfig({
        version: this.versions?.runCode,
        session: payload?.session,
      }),
    );
  }

  @Authorize(AuthLevel.authenticated)
  public getMaterialSolution(
    materialRelationId: number,
    session?: string,
  ): Observable<IMaterialSolutionDTO> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, 'api/solution'),
      {
        ...constructConfig({
          version: this.versions?.getMaterialSolution,
          session,
        }),
        params: {
          materialRelationId,
        },
      },
    );
  }

  @Authorize(AuthLevel.authenticated)
  public removeCourse(alias: string): Observable<null> {
    return this.axiosInstance.post(
      constructUrl(this.environmentUrl, 'api/learn/remove'),
      { alias },
      constructConfig({
        version: this.versions?.removeCourse,
      }),
    );
  }

  @Authorize(AuthLevel.public)
  public getCourseStructure(
    alias: string,
    type: LearningExperienceType,
  ): Observable<ICourseStructureDTO> {
    return this.axiosInstance.get(
      constructUrl(
        this.environmentUrl,
        `api/learningExperienceStructure/${type === LearningExperienceType.LearningPath
          ? 'learningPath'
          : 'course'
        }`,
      ),
      {
        ...constructConfig({
          version: this.versions?.getCourseStructure,
        }),
        params: {
          alias,
        },
      },
    );
  }

  @Authorize(AuthLevel.authenticated)
  public getScreensConfig(source: ScreenSource): Observable<IScreenConfig[]> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, 'api/screen/config'),
      {
        params: {
          source,
        },
      },
    );
  }

  @Authorize(AuthLevel.authenticated)
  public deleteSession(
    materialRelationId: number,
    quitType: LessonSessionQuitType,
  ): Observable<null> {
    return this.axiosInstance.delete(
      constructUrl(
        this.environmentUrl,
        `api/session/${materialRelationId}`,
      ),
      {
        ...constructConfig({
          version: this.versions?.deleteSession,
        }),
        params: {
          quitType,
        },
      },
    );
  }

  @Authorize(AuthLevel.authenticated)
  public getCodeAssistantConfig(): Observable<CodeAssistantConfigType[]> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, 'api/assistant/config'),
    );
  }

  @Authorize(AuthLevel.authenticated)
  public resetProgress(alias: string): Observable<null> {
    return this.axiosInstance.post(
      constructUrl(this.environmentUrl, 'api/learn/resetProgress'),
      { alias },
    );
  }

  @Authorize(AuthLevel.authenticated)
  public updateSelectedLocale(
    alias: string,
    selectedLocale: string,
  ): Observable<null> {
    return this.axiosInstance.put(
      constructUrl(this.environmentUrl, 'api/learn/updateSelectedLocale'),
      { alias, selectedLocale },
    );
  }

  @Authorize(AuthLevel.authenticated)
  public explainAsnwer(body: {
    dynamicId: number;
    answers: IAnswerOption[];
    isCorrect: boolean;
    session: string;
  }) {
    const headers = getLEHeaders(true, true) as unknown;
    return this.fetchService.fetch(
      constructUrl(this.environmentUrl, 'api/assistant/answerexplanation'),
      {
        body: JSON.stringify(body),
        method: 'POST',
        headers: headers as Record<string, string>
      }
    );
  }
}

export * from '../../private/learn-engine-api/learn-engine-api.interface';
