import React, { FC, RefObject, useEffect } from "react";
import s from "./WTACCSuggestedPlan.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";

type Props = {
  data: string;
  sectionOrder: number;
};

export const WTACCSuggestedPlan: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);

  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView('w2a-cc-suggested-plan', sectionOrder);
  }, [isInView]);

  return <div className="wta-cc-suggested-plan" ref={ref as RefObject<HTMLDivElement>}>
    <WTAPaywallV2Typography level="h2" text={'Your readiness: <span style="color: #6869F6">83%</span>'} />
    <div className="wta-cc-suggested-plan-container">
      <WTAPaywallV2Typography className="wta-cc-suggested-plan-text" level="paragraph" text={data} />
      <span>💡</span>
    </div>
  </div>;
};