import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './GenericPaywallPage.scss';
import { PageWrapper } from '../PageWrapper/PageWrapper';
import { PaywallPageButton } from '../PaywallPageButton/PaywallPageButton';
import { PaywallComponent, PaywallComponentType, PaywallPage } from '../../../../api/public/subscription-api';
import { BenefitsList } from '../ui/BenefitsList/BenefitsList';
import { FiveStarReviews } from '../ui/FiveStarReviews/FiveStarReviews';
import { Comparison } from '../ui/Comparison/Comparison';
import { AppleAward } from '../ui/AppleAward/AppleAward';
import { Timeline } from '../ui/Timeline/Timeline';
import { PaywallTitle } from '../ui/PaywallTitle/PaywallTitle';
import { PaywallProduct } from '../ui/PaywallProduct/PaywallProduct';
import { DiscountUnlocked } from '../ui/DiscountUnlocked/DiscountUnlocked';

type GenericPaywallPageProps = {
  page: PaywallPage;
};

export const GenericPaywallPage: FC<GenericPaywallPageProps> = ({
  page
}) => {
  useStyles(s);

  const getComponent = (component: PaywallComponent, i: number) => {
    let definedComponent = null;
    switch (component.type) {
      case PaywallComponentType.benefits:
        definedComponent = <BenefitsList benefits={component.data} />;
        break;
      case PaywallComponentType.fiveStarReviews:
        definedComponent = <FiveStarReviews title={component.data} />;
        break;
      case PaywallComponentType.comparison:
        definedComponent = <Comparison table={component.data} />;
        break;
      case PaywallComponentType.appleAward:
        definedComponent = <AppleAward props={component.data} />;
        break;
      case PaywallComponentType.timeline:
        definedComponent = <Timeline milestones={component.data} />;
        break;
      case PaywallComponentType.title:
        definedComponent = <PaywallTitle text={component.data} />;
        break;
      case PaywallComponentType.product:
        definedComponent = <PaywallProduct product={component.data} />;
        break;
      case PaywallComponentType.discountUnlocked:
        definedComponent = <DiscountUnlocked discountData={component.data} />;
        break;
    }

    return <div className='generic-paywall-page-component' key={component.type + i} style={{ width: '100%', ...component.style }}>{definedComponent}</div>;
  };

  return <PageWrapper
    title={page.title}
    subtitle={page.subtitle}
    headerVersion={page.headerVersion}
    headerComponents={page.headerComponents}
    styles={page.styles}
  >
    <div className="generic-paywall-page">
      {page.components?.map((c, i) => getComponent(c, i))}
      <div className="generic-paywall-page-footer">
        {page.footerButtons?.map(b => (
          <PaywallPageButton
            key={b.text}
            {...b}
            order={page.order}
          />
        ))}
      </div>
    </div>
  </PageWrapper>;
};
