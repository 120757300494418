import { Observable, of } from 'rxjs';

import {
  AxiosInstanceType,
  SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { IApiUrls } from '../private/global.interface';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { SlApiContext } from '../private/api-context';
import { constructUrl } from '../private/utils/httpParamsUtils';
import {
  ISubscriptionApi,
  IUserStatsResponse,
  PaymentProduct,
  PaywallData,
  SubscriptionPlanConfig,
  Touchpoint,
} from '../private/subscription-api/subscription-api.interface';
import { Service, Container, UserTrackService, CourseTrack } from '../../symphony';

@Service()
export class SubscriptionApi implements ISubscriptionApi {
  private environmentUrl: string;
  private axiosInstance: AxiosInstanceType;
  private userTrack: UserTrackService = Container.take('global', UserTrackService);;
  constructor() {
    this.environmentUrl = (
      Container.take('global', 'envUrl') as IApiUrls
    )?.subscriptionApiHost;

    this.axiosInstance = Container.take(
      SlApiContext,
      SlAxiosInstanceService,
    ).axios;
  }

  @Authorize(AuthLevel.authenticated)
  public getUserStats(): Observable<IUserStatsResponse> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, 'api/stats'),
    );
  }

  @Authorize(AuthLevel.public)
  public getPaywall(
    touchpoint: Touchpoint,
    params?: { [key: string]: string; }
  ): Observable<PaywallData> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, 'api/paywall'),
      {
        params: { touchpoint: touchpoint, ...{ ...params, track: this.userTrack.getUserTrack() === CourseTrack.Codding ? 'coding' : 'ai' } }
      }
    );
  }

  @Authorize(AuthLevel.public)
  public getPaymentProducts(): Observable<PaymentProduct[]> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, 'api/products')
    );
  }

  @Authorize(AuthLevel.authenticated)
  public getSubscriptionPlan(): Observable<SubscriptionPlanConfig> {
    return this.axiosInstance.get(
      constructUrl(this.environmentUrl, 'api/subscriptions/plan')
    );
  }
}

export * from '../private/subscription-api/subscription-api.interface';
