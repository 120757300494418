import React, { FC, RefObject, useEffect } from "react";
import s from "./WTACCMoneyBackGuarantee.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { useInView } from "../../../paywalls.utils";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { MoneyBackGuarantee } from "../../ui/icons";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";

type Props = {
  data: {
    title: string;
    description: string;
    caption: string;
  };
  sectionOrder: number;
};

export const WTACCMoneyBackGuarantee: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);

  const { title, description, caption } = data;
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView('w2a-cc-money-back-guarantee', sectionOrder);
  }, [isInView]);

  return <div ref={ref as RefObject<HTMLDivElement>} className="wta-cc-money-back-guarantee">
    <div className="wta-cc-money-back-guarantee-icon">
      <MoneyBackGuarantee />
    </div>
    <WTAPaywallV2Typography className="wta-cc-money-back-guarantee-title" level="h1" text={title} />
    <WTAPaywallV2Typography className="wta-cc-money-back-guarantee-description" level="paragraph" text={description} />
    <WTAPaywallV2Typography className="wta-cc-money-back-guarantee-caption" level="paragraph" text={caption} />
  </div>;
};