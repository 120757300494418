import React, { FC, RefObject, useEffect } from "react";
import s from "./WTACCPaySafe.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";
import { PaymentMethodIcon, PaymentMethodIcons, ShieldIcon } from "../../ui/icons";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";

type Props = {
  data: any;
  sectionOrder: number;
};

export const WTACCPaySafe: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView('w2a-cc-pay-safe', sectionOrder);
  }, [isInView]);

  return <div ref={ref as RefObject<HTMLDivElement>} className="wta-cc-pay-safe">
    <div className="wta-cc-pay-safe-icon">
      <ShieldIcon />
      <span>Pay safe & secure</span>
    </div>
    <div className="wta-cc-pay-safe-icons">
      {PaymentMethodIcon.applePay}
      {PaymentMethodIcon.payPal}
      {PaymentMethodIcon.visa}
      {PaymentMethodIcon.master}
      {PaymentMethodIcon.maestro}
      {PaymentMethodIcon.amex}
      {PaymentMethodIcon.jcb}
      {PaymentMethodIcon.dinersClub}
      {PaymentMethodIcon.discover}
    </div>
    <WTAPaywallV2Typography
      level="paragraph"
      text="Sololearn Inc., Miami Beach, FL, USA"
      className="wta-cc-pay-safe-text"
    />
  </div>;
};