import { Service } from "typedi";
import { AuthService, IUser } from "./auth.service";
import { Container } from "./container.global";
import { ExtTrackingOrchestratorApi } from "../../api/public/ext-tracking-orchestrator-api";
import { v4 as uuidv4 } from 'uuid';
import { firstValueFrom } from "rxjs";
import { getCookie } from "../../../client/utils/cookies";
import { getFingerprintFromCookies } from "../../shared/public/utilsPublic/utilsPublic";
import { PaymentProduct } from "../../api/public/subscription-api";

declare const fbq: (
  trackCustom: 'trackCustom' | 'track',
  eventName: string,
  eventParams: any,
  eventData: any
) => void;

@Service()
export class MetaTrackingService {
  private fnasService = Container.take('global', ExtTrackingOrchestratorApi);
  private authService = Container.take('global', AuthService);

  private getFbParams = (userData?: IUser): {
    fbc: string;
    fbp: string;
    externalId: string;
    email: string;
    eventSourceUrl: string;
    country: string;
  } => {
    const user = userData || this.authService.getUser();
    return {
      fbc: getCookie('_fbc'),
      fbp: getCookie('_fbp'),
      externalId: getFingerprintFromCookies(),
      email: user?.email ? (user.email.endsWith('sololearn.temp') ? null : user.email) : null,
      country: user?.countryCode?.toLowerCase(),
      eventSourceUrl: window.location.href
    };
  };

  public manageWebsiteActivity = (user: IUser) => {
    const setWebsiteActivity = () => {
      localStorage.setItem('websiteActivity', JSON.stringify({
        expiresAt: new Date(new Date().getTime() + 1000 * 60 * 60 * 24).toISOString(),
      }));
    };
    if (!user) {
      localStorage.removeItem('websiteActivity');
    } else {
      const websiteActivity = localStorage.getItem('websiteActivity');
      if (!websiteActivity) {
        setWebsiteActivity();
        this.trackWebsiteActivity(user);
      } else {
        const data = JSON.parse(websiteActivity);
        if (data.expiresAt < new Date().toISOString()) {
          setWebsiteActivity();
          this.trackWebsiteActivity(user);
        }
      }
    }
  };

  public updateMetaUserParams = (params: { key: string, value: string; }[]) => {
    const interval = setInterval(() => {
      if (window && (window as any).fbq) {
        clearInterval(interval);
        params.forEach((param) => {
          const pixel = window['fbq']?.instance?.pixelsByID['908512509704536']?.userData;
          if (pixel) {
            if (param.value) {
              pixel[param.key] = param.value;
            } else {
              delete pixel[param.key];
            }
          }
        });
      }
    }, 1000);
  };

  public trackTrialStarted = (product: PaymentProduct) => {
    const eventId = uuidv4();
    const params = {
      currency: 'USD',
      value: product.discountedAmount,
      predicted_ltv: product.discountedAmount * 1.3 * 0.92
    };
    firstValueFrom(this.fnasService.sendFBEvent({ eventName: 'StartTrial', eventId, ...this.getFbParams(), params }));
    if ((window as any).fbq !== undefined) {
      fbq('track', 'StartTrial', params, { eventID: eventId });
    }
  };

  public trackPurchase = (product: PaymentProduct) => {
    const eventId = uuidv4();
    const params = {
      currency: 'USD',
      content_type: 'product',
      content_ids: [product.key],
      value: product.discountedAmount,
    };
    firstValueFrom(this.fnasService.sendFBEvent({ eventName: 'Purchase', eventId, ...this.getFbParams(), params }));
    if ((window as any).fbq !== undefined) {
      fbq("track", "Purchase", params, { eventID: eventId });
    }
  };

  private trackWebsiteActivity = (user: IUser) => {
    const interval = setInterval(() => {
      if (window && (window as any).fbq !== undefined) {
        const eventId = uuidv4();
        clearInterval(interval);
        (window as any).fbq('trackCustom', 'WebsiteActivity', {}, { eventID: eventId });
        firstValueFrom(this.fnasService.sendFBEvent({ eventName: 'WebsiteActivity', eventId, ...this.getFbParams(user) }));
      }
    }, 1000);
  };
}