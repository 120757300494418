import React, { FC, RefObject, useEffect } from "react";
import s from "./WTAPaywallV2Footer.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallV2Button } from "../components/WTAPaywallV2Button";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { Container } from "../../../../../symphony";
import { CheckoutService } from "../../../services/checkout.service";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { PaywallClickTypes } from "../../../paywalls.constants";
import { useInView } from "../../../paywalls.utils";

type Props = {
  data: {
    hideCTA?: boolean;
  };
  sectionOrder: number;
};

export const WTAPaywallV2Footer: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { scrollToPaymentInfo } = Container.take('paywalls', CheckoutService);
  const { trackClick } = Container.take('paywalls', PaywallsTrackingService);
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);

  const handleCTAClick = () => {
    trackClick(PaywallClickTypes.cta, 'footer');
    scrollToPaymentInfo();
  };

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView('footer', sectionOrder);
  }, [isInView]);

  return <div ref={ref as RefObject<HTMLDivElement>} className="wtapv2-footer">
    {!data?.hideCTA && <WTAPaywallV2Button
      text="Get your plan"
      onClick={handleCTAClick}
    />}
    <WTAPaywallV2Typography
      level="caption"
      text={<p className="wtapv2-footer-address">Sololearn Inc.<br />1521 Alton Rd, #247, Miami Beach, FL, 33139</p>}
    />
  </div>;
};