import React, { FC, RefObject, useEffect, useState } from "react";
import s from "./WTAPaywallV2Summarry.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallV2Label } from "../components/WTAPaywallV2Label";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { WTAPayawllV2Card } from "../components/WTAPayawllV2Card";
import { WTAPaywallIcons } from "../components/WTAPaywallV2Icons";
import { WTAPaywallV2List } from "../components/WTAPaywallV2List";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../../paywalls.store";
import { CheckoutStore } from "../../../checkout.store";
import { WTAPaywallV2Product } from "../../../paywalls.constants";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { Container } from "../../../../../symphony";
import { useInView } from "../../../paywalls.utils";

type Props = {
  data: {
    title: string;
    cards: {
      title: string;
      value: string;
      icon: string;
      className: string;
    }[];
    whatYouGetItems: string[];
    hideSelectedProductLabel: boolean;
    containerStyle?: 'united' | 'default';
  };
  sectionOrder: number;
};

export const WTAPaywallV2Summary: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { title, cards, whatYouGetItems, hideSelectedProductLabel, containerStyle } = data;
  const { externalData } = useContainerData('paywalls', PaywallsStore, ['externalData']);
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);
  const { w2aV2Products, selectedProductKey } = useContainerData('paywalls', CheckoutStore, [
    'w2aV2Products', 'selectedProductKey'
  ]);
  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  const [selectedProduct, setSelectedProduct] = useState<WTAPaywallV2Product>(null);

  useEffect(() => {
    isInView && trackSectionView('summary', sectionOrder);
  }, [isInView]);

  useEffect(() => {
    setSelectedProduct(w2aV2Products?.find(p => p.product.key === selectedProductKey));
  }, [selectedProductKey]);

  const processedCards = cards.map(card => ({
    ...card,
    icon: React.createElement(WTAPaywallIcons[card.icon]),
    value: card.value.match(/\[\[(.*?)\]\]/)
      ? externalData?.wtaAnswers?.[card.value.match(/\[\[(.*?)\]\]/)[1]]
      : card.value
  }));

  return <div ref={ref as RefObject<HTMLDivElement>} className="wtapv2-summary">
    {selectedProduct && !hideSelectedProductLabel && <WTAPaywallV2Label
      text={selectedProduct.meta.label.text}
      theme={selectedProduct.meta.label.theme}
      icon={selectedProduct.meta.label.icon && React.createElement(WTAPaywallIcons[selectedProduct.meta.label.icon])}
      className="wtapv2-summary-label"
    />}
    <WTAPaywallV2Typography
      level="h1"
      text={title}
    />
    <div className={`wtapv2-summary-cards ${containerStyle === 'united' ? 'united' : ''}`}>
      {processedCards.map((card) => <WTAPayawllV2Card
        key={card.title}
        className={`wtapv2-summary-card ${card.className} ${containerStyle === 'united' ? 'united' : ''}`}
        styles={{ padding: '16px' }}
      >
        <div className="wtapv2-summary-card-inner">
          <div className="wtapv2-summary-card-inner-icon">
            {card.icon}
          </div>
          <div className="wtapv2-summary-card-inner-content">
            <WTAPaywallV2Typography
              level="caption"
              text={card.title}
              className="wtapv2-summary-card-inner-content-text nowrap"
            />
            <WTAPaywallV2Typography
              level="h2"
              text={card.value}
              className="wtapv2-summary-card-inner-content-text"
            />
          </div>
        </div>
      </WTAPayawllV2Card>)}
    </div>
    {whatYouGetItems && whatYouGetItems.length > 0 && <>
      <WTAPaywallV2Typography
        level="h3"
        text="What you'll get:"
      />
      <WTAPaywallV2List
        size="small"
        className="wtapv2-summary-list"
        items={whatYouGetItems}
      />
    </>}
  </div>;
};
