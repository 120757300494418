import React, { FC, RefObject, useEffect, useState } from "react";
import s from "./WTACCMobileApp.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { useKeenSlider } from "keen-slider/react";

type Props = {
  data: {
    title: string;
    images: string[];
  };
  sectionOrder: number;
};

export const WTACCMobileApp: FC<Props> = ({ data, sectionOrder }) => {
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);
  const [currentSlide, setCurrentSlide] = useState(2);
  const [loaded, setLoaded] = useState(false);

  const { title, images } = data;

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 2,
    loop: true,
    slides: {
      origin: "center",
      perView: 2,
      spacing: 22,
    },
    created() {
      setLoaded(true);
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  useEffect(() => {
    isInView && trackSectionView('w2a-cc-mobile-app', sectionOrder);
  }, [isInView]);

  useStyles(s);
  return <div ref={ref as RefObject<HTMLDivElement>} className="wta-cc-mobile-app">
    <WTAPaywallV2Typography className="wta-cc-mobile-app-title" level="h1" text={title} />
    <div ref={sliderRef} className="wta-cc-mobile-app-slider keen-slider">
      {images.map((image, index) => (
        <div key={index} className="keen-slider__slide">
          <img className="wta-cc-mobile-app-slider-image" src={image} alt={`Slide ${index}`} />
        </div>
      ))}
    </div>
    {loaded && instanceRef.current && (
      <div className="wta-cc-mobile-app-slider-controls">
        <Arrow
          left
          onClick={(e) =>
            e.stopPropagation() || instanceRef.current?.prev()
          }
          disabled={currentSlide === 0}
        />
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys() as any,
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => { instanceRef.current?.moveToIdx(idx); }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            );
          })}
        </div>
        <Arrow
          onClick={(e) =>
            e.stopPropagation() || instanceRef.current?.next()
          }
          disabled={
            currentSlide ===
            instanceRef.current.track.details.slides.length - 1
          }
        />
      </div>
    )}
  </div>;
};

const Arrow = (props) => {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${props.left ? "arrow--left" : "arrow--right"
        } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
};