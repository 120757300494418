import React, { FC, RefObject, useEffect, useState } from "react";
import s from "./WTACCIntroOffer.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";
import { WTAPaywallV2Product } from "../../../paywalls.constants";

type Props = {
  data: {
    text: string;
  };
  sectionOrder: number;
};

export const WTACCIntroOffer: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);
  const [ref, isInView] = useInView({
    threshold: 0.7
  });
  const { w2aV2Products, selectedProductKey } = useContainerData('paywalls', CheckoutStore, [
    'w2aV2Products', 'selectedProductKey'
  ]);
  const [selectedProduct, setSelectedProduct] = useState<WTAPaywallV2Product>(null);
  const [processedText, setProcessedText] = useState<string>('');

  useEffect(() => {
    setSelectedProduct(w2aV2Products?.find(p => p.product.key === selectedProductKey));
  }, [selectedProductKey]);

  useEffect(() => {
    if (selectedProduct) {
      const processedText = data.text
        .replace('[[planName]]', `${selectedProduct?.meta.name}`)
        .replace('[[discountedPrice]]', `${selectedProduct?.product.discountedAmount}`)
        .replace('[[originalPrice]]', `${selectedProduct?.product.amount}`);
      setProcessedText(processedText);
    }
  }, [selectedProduct]);

  useEffect(() => {
    isInView && trackSectionView('w2a-cc-intro-offer', sectionOrder);
  }, [isInView]);

  return <div ref={ref as RefObject<HTMLDivElement>} className="wta-cc-intro-offer">
    {processedText}
  </div>;
};
