export enum LearningExperienceType {
  Course = 1,
  LearningPath = 2,
  FakeLearningPath = 9,
}

export enum EnrollmentStatus {
  None = 0,
  Enrolled = 1,
  Removed = 2,
  EnrolledFromPath = 3,
}

export enum ProgressionStatus {
  None = 0,
  InProgress = 1,
  Completed = 2,
}

export enum OwnershipRequirementType {
  Free = 0,
  Pro = 1,
  Bits = 2,
  Paid = 3,
}

export interface IOwnershipStatus {
  isOwned: boolean;
  ownershipRequirementsTypeId: OwnershipRequirementType;
}

export enum ProgrammingLanguage {
  All = 0,
  Cpp = 1,
  C = 2,
  Cs = 3,
  Java = 4,
  Py = 5,
  Php = 6,
  Rb = 7,
  Kt = 8,
  Swift = 9,
  Node = 10,
  Js = 11,
  Sql = 12,
  Go = 13,
  R = 14,
  Html = 15,
  Css = 16,
  Web = 17,
  TS = 18,
}

export interface ICodeEditor {
  languageId: ProgrammingLanguage;
  isDefault: boolean;
}

export enum CourseTrack {
  Codding = 1,
  AI = 2,
}

export enum CourseLevelId {
  Beginner = 1,
  Intermediate = 2,
  Advanced = 3,
}

export enum CourseCategory {
  All = 0,
  Websites = 1,
  MobileApps = 2,
  GamesMobileWeb = 3,
  BackendSystems = 4,
  DataScience = 5,
  AI = 7,
}

export interface IUiConfigurations {
  iconUrl?: string;
  color?: string;
  resourceUrl?: string;
  languageId?: ProgrammingLanguage;
  codeEditors?: ICodeEditor[];
  isTop?: boolean;
}

export interface ILearningExperience {
  id: number;
  name: string;
  alias: string;
  description: string;
  typeId: LearningExperienceType;
  enrollmentStatusId: EnrollmentStatus;
  progressionStatusId: ProgressionStatus;
  orderNumber: number;
  lastActivityDate: string;
  uiConfigurations: IUiConfigurations;
  track: CourseTrack;
  levelId?: CourseLevelId;
  categoryIds?: CourseCategory[];
  ownership: IOwnershipStatus;
  startDate?: string;
  // shortDescription: string
  // bundle: null;
}

export enum LeagueRank {
  Mercury = 1,
  Venus = 2,
  Earth = 3,
  Mars = 4,
  Jupiter = 5,
  Saturn = 6,
}

export enum LeaderboardState {
  Open = 1,
  StartedAndOpen = 2,
  StartedAndClosed = 3,
  Ended = 4,
}

export enum UserState {
  Ok = 1,
  NotEnoughXp = 2,
  DoAction = 3,
}

export enum UserPromotion {
  LevelUp = 1,
  Freeze = 2,
  LevelDown = 3,
}

export enum LeaderBoardMessages {
  JoinLeaderBoard = 'JoinLeaderBoard',
  MoveUpNextLeague = 'MoveUpNextLeague',
  TopPlaceInTheLastLeague = 'TopPlaceInTheLastLeague',
  NumberOneInLastLeague = 'NumberOneInLastLeague',
}

export interface IBitSource {
  id: BitSourceType;
  name: BitSourceName;
  price: number;
  formulaId: number;
}

export enum BitSourceType {
  LessonComplete = 1,
  WinChalange = 2,
  EOMComplete = 4,
  CodeCoachCompletion = 10,
  CodeRepoCommitting = 11,
  LeaderboardPlace1 = 13,
  LeaderboardPlace2 = 14,
  LeaderboardPlace3 = 15,
  BoosterLessonComplete = 16,
}

export enum BitSourceName {
  LessonComplete = 'Lesson Complete',
  WinChalange = 'Win Chalange',
  EOMComplete = 'EOM Complete',
  CodeCoachCompletion = 'CodeCoachCompletion',
  CodeRepoCommitting = 'CodeRepoCommitting',
  LeaderboardPlace1 = 'LeaderboardPlace_1',
  LeaderboardPlace2 = 'LeaderboardPlace_2',
  LeaderboardPlace3 = 'LeaderboardPlace_3',
}

export interface IXpSources {
  xpSources: IXpSource[];
  dailyStreak: IDailyStreakXpSource[];
}

export interface IXpSource {
  sourceName: XpSourceName;
  xpSource: XpSourceType;
  xp: number;
}

export enum XpSourceName {
  LessonComplete = 'LessonComplete',
  CourseComplete = 'CourseComplete',
  BoosterComplete = 'BoosterComplete',
  DailyGoal = 'DailyGoal',
  CodeRepoCommit = 'CodeRepoCommit',
  CodeCoachSolve = 'CodeCoachSolve',
  EOMSolve = 'EOMSolve',
  Challenge = 'Challenge',
}

export enum XpSourceType {
  LessonComplete = 1,
  CourseComplete = 2,
  DailyGoal = 3,
  CodeRepoCommit = 4,
  CodeCoachSolve = 5,
  EOMSolve = 6,
  Challenge = 9,
  BoosterComplete = 12,
}

export interface IDailyStreakXpSource {
  day: number;
  xp: number;
}

export enum XpSourceGroupType {
  XpSources = 'xpSources',
  DailyStreak = 'dailyStreak',
}

export interface IXpSourceParams {
  type: XpSourceGroupType.XpSources;
  value: XpSourceName;
}

export interface IDailyStreakParams {
  type: XpSourceGroupType.DailyStreak;
  value: number;
}

export interface IShopItem {
  id: ShopItemType;
  name: ShopItemName;
  price: number;
}

export enum ShopItemType {
  CodeCoach = 1,
  QuizAnswer = 2,
  QuizHint = 3,
  HeartRefill = 7,
  QuestionMaterialAnswer = 8,
  StreakFreeze = 9,
}

export enum ShopItemName {
  CodeCoach = 'CodeCoach',
  QuizAnswer = 'QuizAnswer',
  QuizHint = 'QuizHint',
  HeartRefill = 'HeartRefill',
  QuestionMaterialAnswer = 'QuestionMaterialAnswer',
  StreakFreeze = 'StreakFreeze',
}

export interface IUserSetting {
  id?: number;
  key: string;
  value: string;
}

export enum NavigationItem {
  Courses = 1,
  CodeCompiler,
  Leaderboard,
  CodeBits,
  Discuss,
  Blog,
  Teams,
  Pricing,
  GetPro,
}

export type NavItemBadgeConfig = {
  [key in NavigationItem]?: boolean;
};
