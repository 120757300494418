import { Observable } from 'rxjs';

import { IOnboardingResponse } from '../private/onboarding-api/onboarding-api.interface';
import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { IApiUrls } from '../private/global.interface';
import {
  AxiosInstanceType,
  SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { IExtTrackingOrchestratorApi } from '../private/ext-tracking-orchestrator-api/ext-tracking-orchestrator-api.interface';
import { constructUrl } from '../private/utils/httpParamsUtils';
import { Service, Container } from '../../symphony';

@Service()
export class ExtTrackingOrchestratorApi implements IExtTrackingOrchestratorApi {
  private environmentUrl: string;
  private fnasUrl: string;

  private axiosInstance: AxiosInstanceType;

  constructor() {
    const apiURLs: IApiUrls = Container.take('global', 'envUrl') as IApiUrls;
    this.environmentUrl = apiURLs.extTrackingOrchestratorApiHost;
    this.fnasUrl = apiURLs.extTrackingFacebookApiHost;

    this.axiosInstance = Container.take(
      SlApiContext,
      SlAxiosInstanceService,
    ).axios;
  }

  @Authorize(AuthLevel.public)
  public startSessionEvent(
    userId: number,
    userEmail: string,
    ipAddress: string,
  ): Observable<IOnboardingResponse> {
    const platformId = 1143;
    const type = 'SessionStartedEvent';

    return this.axiosInstance.post(
      constructUrl(this.environmentUrl, 'api/events'),
      {
        type,
        payload: {
          user: {
            id: userId,
            email: userEmail,
          },
          device: {
            platform: platformId,
            ipAddress,
          },
        },
      },
    );
  }

  @Authorize(AuthLevel.public)
  public sendFBEvent(payload: {
    eventName: string;
    eventId: string;
    eventSourceUrl: string;
    fbc: string;
    fbp: string;
    externalId: string;
    email: string;
    country: string;
    params?: Record<string, unknown>;
  }): Observable<any> {
    return this.axiosInstance.post(
      constructUrl(this.fnasUrl, 'api/fnas'),
      payload
    );
  }
}

export * from '../private/ext-tracking-orchestrator-api/ext-tracking-orchestrator-api.interface';
