import React, { CSSProperties, FC, ReactNode } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PageWrapper.scss';
import { i18n } from '../../../../symphony';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { PaywallsStore } from '../../paywalls.store';
import { PaywallHeader } from '../ui/PaywallHeader/PaywallHeader';
import { PaywallHeaderComponent } from '../../../../api/public/subscription-api';
import { PaywallHeaderComponentsDrawer } from '../PaywallHeaderComponentsDrawer/PaywallHeaderComponentsDrawer';

type PageWrapperProps = {
  children: ReactNode;
  title: string;
  subtitle?: string;
  styles?: {
    wrapper?: CSSProperties;
    header?: CSSProperties;
    body?: CSSProperties;
    title?: CSSProperties;
  };
  headerComponents: PaywallHeaderComponent[];
  headerVersion: 1 | 2;
};

export const PageWrapper: FC<PageWrapperProps> = ({
  children, title, subtitle, styles, headerVersion = 1, headerComponents = []
}) => {
  useStyles(s);

  const { paywallData } = useContainerData('paywalls', PaywallsStore, [
    'paywallData'
  ]);

  return <div className={`page-wrapper ${paywallData?.metadata.theme}`} style={styles?.wrapper}>
    <div className="page-wrapper-header" style={styles?.header}>
      <div className="page-wrapper-navigation">
        <PaywallHeader version={headerVersion} />
      </div>
      <h1 className="page-wrapper-title" style={styles?.title}>{i18n.t(title)}</h1>
      {subtitle && <h3 className='page-wrapper-subtitle'>{i18n.t(subtitle)}</h3>}
      {headerComponents && headerComponents?.length ? <div className='page-wrapper-components'>
        {headerComponents.map(comp => (<PaywallHeaderComponentsDrawer key={comp.type} component={comp} />))}
      </div> : null}
    </div>
    <div className="page-wrapper-body" style={styles?.body}>
      <div className="page-wrapper-body-inner">
        {children}
      </div>
    </div>
  </div>;
};