import React, { FC, RefObject, useEffect, useState } from "react";
import useStyles from "isomorphic-style-loader/useStyles";
import s from "./WTAPaywallV2BeforeAfter.scss";
import { WTAPayawllV2Card } from "../components/WTAPayawllV2Card";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../../paywalls.store";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";

type Props = {
  data: any;
  sectionOrder: number;
};

export const WTAPaywallV2BeforeAfter: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);
  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView('before-after', sectionOrder);
  }, [isInView]);

  const { heading, value, before, after } = data;
  const [processedValue, setProcessedValue] = useState<string>(null);
  const { externalData } = useContainerData('paywalls', PaywallsStore, ['externalData']);

  useEffect(() => {
    setProcessedValue(value.match(/\[\[(.*?)\]\]/)
      ? externalData?.wtaAnswers?.[value.match(/\[\[(.*?)\]\]/)[1]]
      : value);
  }, [value]);

  return <div ref={ref as RefObject<HTMLDivElement>} className="wtapv2-before-after">
    <WTAPayawllV2Card className="wtapv2-before-after-card">
      <WTAPaywallV2Typography text={heading} level={'caption'} />
      <WTAPaywallV2Typography text={processedValue} level={'h2'} />
      <div className="wtapv2-before-after-divider"></div>
      <div className="wtapv2-before-after-container">
        <div className="wtapv2-before-after-section">
          <WTAPaywallV2Typography text={'NOW:'} level={'caption'} />
          <img className="wtapv2-before-after-image before" src={`https://blob.sololearn.com/web-assets/B_${processedValue}.png`} alt="before" />
        </div>
        <div className="wtapv2-before-after-section">
          <WTAPaywallV2Typography text={'AFTER PROGRAM:'} level={'caption'} />
          <img className="wtapv2-before-after-image" src={`https://blob.sololearn.com/web-assets/A_${processedValue}.png`} alt="after" />
        </div>
      </div>
    </WTAPayawllV2Card>
  </div>;
};