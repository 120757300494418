import React, { FC, RefObject, useEffect } from "react";
import s from "./WTACCWhatYouGet.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";

type Props = {
  data: {
    title: string;
    items: string[];
  };
  sectionOrder: number;
};

export const WTACCWhatYouGet: FC<Props> = ({ data, sectionOrder }) => {
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView('w2a-cc-what-you-get', sectionOrder);
  }, [isInView]);

  useStyles(s);
  return <div ref={ref as RefObject<HTMLDivElement>} className="wta-cc-what-you-get">
    <WTAPaywallV2Typography className="wta-cc-what-you-get-title" level="h1" text={data.title} />
    <div className="wta-cc-what-you-get-items">
      {data.items.map((item, index) => (
        <div key={index} className="wta-cc-what-you-get-item">
          <div className="wta-cc-what-you-get-item-icon"></div>
          <WTAPaywallV2Typography className="wta-cc-what-you-get-item-title" level="paragraph" text={item} />
        </div>
      ))}
    </div>
  </div>;
};;