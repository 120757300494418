import React, { FC, RefObject, useEffect } from "react";
import s from "./WTACCBeforeAfter.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../../paywalls.store";

type Props = {
  data: {
    imageUrl: string;
    progressTitles: string[];
    progressLabels: string[];
    progressValues: number[];
  };
  sectionOrder: number;
};

export const WTACCBeforeAfter: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { imageUrl } = data;
  const { externalData } = useContainerData('paywalls', PaywallsStore, ['externalData']);
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  const processUrl = (url: string) => {
    return url.replace(/\{\{(.*?)\}\}/g, (match, key) => externalData?.wtaAnswers[key || match]);
  };

  useEffect(() => {
    isInView && trackSectionView('w2a-cc-before-after', sectionOrder);
  }, [isInView]);

  return <div>
    <div className="wta-cc-before-after" ref={ref as RefObject<HTMLDivElement>}>
      <div className="wta-cc-before-after-header">
        <span>Now</span>
        <span>Goal</span>
      </div>
      <img className="wta-cc-before-after-image" src={processUrl(imageUrl)} alt="before" />
      <div className="wta-cc-before-after-footer">
        <div className="wta-cc-before-after-footer-item">
          <div>
            <span className="wta-cc-before-after-title">{data.progressTitles ? data.progressTitles[0] : 'AI skills'}</span>
            <div className="wta-cc-before-after-progress">
              <div className="wta-cc-before-after-progress-button yellow" style={{ left: data.progressValues ? `${data.progressValues[0]}%` : '50%' }}></div>
            </div>
            <span className="wta-cc-before-after-caption">{data.progressLabels ? data.progressLabels[0] : 'Moderate'}</span>
          </div>
          <div>
            <span className="wta-cc-before-after-title">{data.progressTitles ? data.progressTitles[1] : 'Income potential'}</span>
            <div className="wta-cc-before-after-progress flat">
              <div className="wta-cc-before-after-progress-button blue" style={{ left: data.progressValues ? `${data.progressValues[1]}%` : '50%' }}></div>
              <div className="wta-cc-before-after-progress-fill" style={{ width: data.progressValues ? `${data.progressValues[1] + 1}%` : '51%' }}></div>
            </div>
            <span className="wta-cc-before-after-caption">{data.progressLabels ? data.progressLabels[1] : 'Limited'}</span>
          </div>
        </div>
        <div className="wta-cc-before-after-footer-item">
          <div>
            <span className="wta-cc-before-after-title">{data.progressTitles ? data.progressTitles[2] : 'AI skills'}</span>
            <div className="wta-cc-before-after-progress">
              <div className="wta-cc-before-after-progress-button" style={{ left: data.progressValues ? `${data.progressValues[2]}%` : '80%' }}></div>
            </div>
            <span className="wta-cc-before-after-caption">{data.progressLabels ? data.progressLabels[2] : 'High'}</span>
          </div>
          <div>
            <span className="wta-cc-before-after-title">{data.progressTitles ? data.progressTitles[3] : 'Income potential'}</span>
            <div className="wta-cc-before-after-progress flat">
              <div className="wta-cc-before-after-progress-button blue" style={{ left: data.progressValues ? `${data.progressValues[3]}%` : '80%' }}></div>
              <div className="wta-cc-before-after-progress-fill" style={{ width: data.progressValues ? `${data.progressValues[3] + 1}%` : '81%' }}></div>
            </div>
            <span className="wta-cc-before-after-caption">{data.progressLabels ? data.progressLabels[3] : 'High'}</span>
          </div>
        </div>
      </div>
    </div>
    <span className="wta-cc-before-after-disclaimer">This is not a guarantee or promise of results.</span>
  </div>;
};