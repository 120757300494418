import React, { FC, RefObject, useEffect } from "react";
import s from "./WTACCEasyAI.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../../paywalls.store";

type Props = {
  data: {
    imageUrl: string;
    title: string;
    items: string[];
  };
  sectionOrder: number;
};

export const WTACCEasyAI: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { imageUrl, title, items } = data;
  const { externalData } = useContainerData('paywalls', PaywallsStore, ['externalData']);
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  const processUrl = (url: string) => {
    return url.replace(/\{\{(.*?)\}\}/g, (match, key) => externalData?.wtaAnswers[key || match]);
  };

  useEffect(() => {
    isInView && trackSectionView('w2a-cc-easy-ai', sectionOrder);
  }, [isInView]);

  return <div ref={ref as RefObject<HTMLDivElement>} className="wta-cc-easy-ai" >
    <WTAPaywallV2Typography className="wta-cc-easy-ai-title" level="h1" text={title} />
    <img className="wta-cc-easy-ai-image" src={processUrl(imageUrl)} alt="AI" />
    <ul className="wta-cc-easy-ai-items">
      {items.map((item, index) => (
        <li key={index} className="wta-cc-easy-ai-item">
          <span>👌</span>
          <WTAPaywallV2Typography level="paragraph" text={item} />
        </li>
      ))}
    </ul>
  </div>;
};