/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import {
  EMPTY,
  Subject, catchError, combineLatest, filter, merge, of, switchMap, takeUntil, tap,
} from 'rxjs';

import {
  AuthService, BitService, Container, DataService, HeartService, IUser, IXpSources, LeaderboardService, ModalService, StorageService, StreakService, TrackingEventNames, TrackingService, SubscriptionPlanService,
  UserTrackService, MetaTrackingService
} from '../../features/symphony';

import { ISlStorageHardBlocker, SlStorageKeys } from '../../features/shared/public/globalInterfaces/globalInterfaces';
import { SocketService } from '../socket.service';
import { triggerLeaderboardBadge } from './triggerLeaderboardBadge';
import { SlModalIds } from '../shared/constants';
import { ILearnEngineApi } from '../../features/api/private/learn-engine-api/learn-engine-api.interface';
import { AbTestingApi, IAbTestingApi, IGetScreenConfigsDTO } from '../../features/api/public/abTesting-api';
import { IGamificationApi, GamificationApi, ShopItemTypes } from '../../features/api/public/gamification-api';
import { IKudosApi, KudosApi } from '../../features/api/public/kudos-api';
import { LearnEngineApi } from '../../features/api/public/learn-engine/learn-engine-api';
import { StaticPagesAPI, IStaticPageResponse } from '../../features/api/public/static-pages-api';
import { IStreakApi, StreakApi } from '../../features/api/public/streak-api';
import { IUserSettingsApi, UserSettingsApi, AppSettingKeys } from '../../features/api/public/user-settings-api';
import { IXpApi, XpApi } from '../../features/api/public/xp-api';
import { SubscriptionApi } from '../../features/api/public/subscription-api';

export const useUserInit = () => {
  const authService = Container.take('global', AuthService);
  const dataService = Container.take('global', DataService);
  const abTestingApi = Container.take('global', AbTestingApi);
  const bitService = Container.take('global', BitService);

  const unsubscriber = new Subject<void>();
  const userInfoUnsubscriber = new Subject<void>();
  let localUserData = null;
  const [modalsData, setModalsData] = useState(null);

  const blockerPopUpHandler = (userData: IUser): void => {
    // TODO: to be taken to other service
    const url = location.pathname;
    const modalService = Container.take('global', ModalService);
    const screenContentConfigNames = {
      terms: 'privacy_policy',
      privacy: 'terms_and_conditions',
    };

    if (!userData) {
      modalService.close(SlModalIds.termsAndConditionModal);
      modalService.close(SlModalIds.privacyPolicyModal);
      localUserData = null;
    } else if (!localUserData) {
      localUserData = userData;
      const { tracking$ }: any = Container.take(
        'global',
        TrackingService,
      );
      const storageService: any = Container.take(
        'global',
        StorageService,
      );
      const hardBlocker: ISlStorageHardBlocker = storageService.load(
        SlStorageKeys.hardBlocker,
        'session',
      );
      const userSettings: IUserSettingsApi = new UserSettingsApi();
      const abTesting: IAbTestingApi = new AbTestingApi();

      combineLatest([
        userSettings.getAppSettings(),
        userSettings.getUserSettings(),
      ]).pipe(catchError(() => EMPTY)).subscribe(([appsetting, userSetting]) => {
        dataService.userSettings$.next(userSetting?.data?.userSettings);
        const {
          termsAndConditionsVersion: appTermsAndConditionsVersion,
          privacyPolicyVersion: appPrivacyPolicyVersion,
        } = appsetting.data.appSetting;

        if (userData.isNewRegisteredUser) {
          const toBeUpdatedKeys = [];
          let sessionStorageKeys = null;
          if (appPrivacyPolicyVersion) {
            toBeUpdatedKeys.push({
              key: AppSettingKeys.privacyPolicyVersion,
              value: JSON.stringify({
                version: appPrivacyPolicyVersion,
                timestamp: Math.floor(Date.now() / 1000),
              }),
            });
            sessionStorageKeys = {
              ...sessionStorageKeys,
              privacyPolicy: String(appPrivacyPolicyVersion),
            };
          }
          if (appTermsAndConditionsVersion) {
            toBeUpdatedKeys.push({
              key: AppSettingKeys.termsAndConditionsVersion,
              value: JSON.stringify({
                version: appTermsAndConditionsVersion,
                timestamp: Math.floor(Date.now() / 1000),
              }),
            });
            sessionStorageKeys = {
              ...sessionStorageKeys,
              termOfConditions: String(
                appTermsAndConditionsVersion,
              ),
            };
          }
          toBeUpdatedKeys.length > 0
            && userSettings
              .updateUserSettings(toBeUpdatedKeys)
              .subscribe();
          sessionStorageKeys
            && storageService.save(
              SlStorageKeys.hardBlocker,
              sessionStorageKeys,
              'session',
            );
        } else {
          const staticPagesApi = new StaticPagesAPI();
          const termOfConditions = userSetting.data.userSettings.find(
            (item) => item.key
              === AppSettingKeys.termsAndConditionsVersion,
          );
          const userTermOfUsVersion = termOfConditions?.value
            ? JSON.parse(termOfConditions.value)?.version
            : 0;
          let modals = modalsData;

          if (
            !hardBlocker?.termOfConditions
            && appTermsAndConditionsVersion !== 0
            && appTermsAndConditionsVersion > userTermOfUsVersion
            && !url.includes('terms-of-use')
            && !url.includes('termsOfUse')
          ) {
            combineLatest([
              staticPagesApi.getPageContent('terms-of-use'),
              abTestingApi.getScreenConfigs(['popups']),
            ])
              .pipe(takeUntil(userInfoUnsubscriber))
              .subscribe(
                ([pageData, popupData]: [
                  IStaticPageResponse,
                  IGetScreenConfigsDTO,
                ]) => {
                  if (!authService.userInfo$.value) {
                    return of(null);
                  }
                  const termsPopupData = popupData.data?.screenContent?.find((content) => content.name === screenContentConfigNames.terms);

                  modals = {
                    ...modals,
                    [SlModalIds.termsAndConditionModal]: {
                      staticData: termsPopupData,
                      version:
                        appTermsAndConditionsVersion,
                      htmlContent:
                        pageData.data.pageContent,
                    },
                  };
                  setModalsData(modals);
                  modalService.open(
                    SlModalIds.termsAndConditionModal,
                  );
                  tracking$.next({
                    eventName:
                      TrackingEventNames.slTermOfConditionsPopUpView,
                    eventParams: {
                      action: 'view',
                    },
                  });
                },
              );
          }

          const privacyPolicy = userSetting.data.userSettings.find(
            (item) => item.key === AppSettingKeys.privacyPolicyVersion,
          );
          const userPrivacyPolicyVersion = privacyPolicy?.value
            ? JSON.parse(privacyPolicy.value)?.version
            : 0;

          if (
            !hardBlocker?.privacyPolicy
            && appPrivacyPolicyVersion !== 0
            && appPrivacyPolicyVersion > userPrivacyPolicyVersion
            && !url.includes('privacy-policy')
            && !url.includes('privacyPolicy')
          ) {
            combineLatest([
              staticPagesApi.getPageContent('privacy-policy'),
              abTestingApi.getScreenConfigs(['popups']),
            ])
              .pipe(takeUntil(userInfoUnsubscriber))
              .subscribe(
                ([pageData, popupData]: [
                  IStaticPageResponse,
                  IGetScreenConfigsDTO,
                ]) => {
                  if (!authService.userInfo$.value) {
                    return of(null);
                  }
                  const privacyPolicyPopupData = popupData.data?.screenContent?.find((content) => content.name === screenContentConfigNames.privacy);

                  modals = {
                    ...modals,
                    [SlModalIds.privacyPolicyModal]: {
                      staticData: privacyPolicyPopupData,
                      version: appPrivacyPolicyVersion,
                      htmlContent:
                        pageData.data.pageContent,
                    },
                  };
                  setModalsData(modals);
                  modalService.open(
                    SlModalIds.privacyPolicyModal,
                  );
                  tracking$.next({
                    eventName:
                      TrackingEventNames.slPrivacyPolicyPopUpView,
                    eventParams: {
                      action: 'view',
                    },
                  });
                },
              );
          }
        }
      });
    }
  };

  useEffect(() => {
    const heartService = Container.take('global', HeartService);
    const leaderBoardService = Container.take('global', LeaderboardService);
    const streakService = Container.take('global', StreakService);
    const subscriptionPlanService = Container.take('global', SubscriptionPlanService);
    // const userData: IUser = authService.getUser();
    const userTrackService = Container.take('global', UserTrackService);
    const metaTrackingService = Container.take('global', MetaTrackingService);

    /**
     * initializes socket service, workaround to not use @Service annotation,
     * as it introduces a memory leak in our application
     */
    new SocketService();

    const gamificationApi: IGamificationApi = Container.take(
      'global',
      GamificationApi,
    );
    const kudosApi: IKudosApi = Container.take('global', KudosApi);
    const xpApi: IXpApi = Container.take('global', XpApi);
    const streakApi: IStreakApi = Container.take('global', StreakApi);
    const learnEngineApi: ILearnEngineApi = Container.take('global', LearnEngineApi);
    const subscriptionApi = Container.take('global', SubscriptionApi);

    const isLoggedIn: boolean = authService.isLoggedIn();
    if (isLoggedIn) {
      streakService.setBuyStreakSaver(() => gamificationApi.buyShopItem({
        itemType: ShopItemTypes.streakfreeze,
        itemId: 0,
      }));
    }

    combineLatest([
      subscriptionPlanService.subscriptionPlanConfigs.pipe(filter(t => !!t)),
      merge(
        heartService.nextRefillDateTimer$.pipe(filter(t => t)),
        userTrackService.userTrack.pipe(filter(t => !!t))
      )
    ]).pipe(
      switchMap(() => {
        if (authService.userInfo$.value) {
          return kudosApi.getHearts();
        }
        return of(null);
      })
    ).subscribe((hearts) => {
      heartService.hearts$.next(hearts?.data || null);
    });

    // Need to define proper place for functions included in mount
    let userId: number;
    const timeZone = new Date().getTimezoneOffset() / -60; // Test
    authService.userInfo$.subscribe((user: IUser) => {
      if (user) {
        metaTrackingService.updateMetaUserParams([
          { key: 'em', value: user.email },
          { key: 'country', value: user.countryCode?.toLowerCase() }
        ]);
      } else {
        metaTrackingService.updateMetaUserParams([
          { key: 'em', value: null },
          { key: 'country', value: null }
        ]);
      }
      metaTrackingService.manageWebsiteActivity(user);
      if (
        !dataService.learningExperiences$.value
        || (!user && userId)
        || (user?.id !== userId)
      ) {
        learnEngineApi.getLearningExperiences().pipe(
          tap((le) => { dataService.learningExperiences$.next(le); }),
          catchError(() => of(null)),
        ).pipe(takeUntil(unsubscriber)).subscribe();
      }

      if (user && user.id !== userId) {
        userId = user.id;

        merge(
          gamificationApi.getBits().pipe(
            tap(({ data }) => {
              if (data) {
                bitService.bits$.next(data);
              }
            }),
            catchError(() => of(null)),
          ),
          gamificationApi.getBitSourcesList().pipe(
            tap(({ data }) => {
              if (data) {
                dataService.bitSourcesList$.next(data);
              }
            }),
            catchError(() => of(null)),
          ),
          gamificationApi.getShopItemsList().pipe(
            tap(({ data }) => {
              if (data) {
                dataService.shopItemsList$.next(data);
              }
            }),
            catchError(() => of(null)),
          ),
          xpApi.getXpSources().pipe(
            tap((data: IXpSources) => {
              if (data) {
                dataService.xpSourcesList$.next(data);
              }
            }),
            catchError(() => of(null)),
          ),
          streakApi.getStreaks(userId, timeZone).pipe(
            tap((data) => {
              if (data) {
                streakService.streakDTO$.next(data);
              }
            }),
            catchError(() => of(null)),
          ),
          streakApi.getStreakConfiguration().pipe(
            tap((data) => {
              if (data) {
                streakService.configuration$.next(data);
              }
            }),
            catchError(() => of(null)),
          ),
          subscriptionApi.getSubscriptionPlan().pipe(
            tap(data => {
              subscriptionPlanService.subscriptionPlanConfigs.next(data);
            }),
            catchError(() => of(null))
          )
        )
          .pipe(takeUntil(unsubscriber))
          .subscribe();
        triggerLeaderboardBadge(unsubscriber);
      }

      if (!user) {
        bitService.bits$.next(null);
        dataService.bitSourcesList$.next(null);
        streakService.streakDTO$.next(null);
        userId = null;
        heartService.hearts$.next(null);
        leaderBoardService.leaderboardExperiment$.next(null);
        subscriptionPlanService.subscriptionPlanConfigs.next(null);
        userInfoUnsubscriber.next(null);
        userInfoUnsubscriber.complete();
      }
      blockerPopUpHandler(user);
    });

    return () => {
      unsubscriber.next(null);
      unsubscriber.complete();
    };
  }, []);

  return { modalsData };
};
