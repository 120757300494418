import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import s from "./WTAPaywallV2PaymentInfo.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallV2Label } from "../components/WTAPaywallV2Label";
import { WTAPaywallIcons } from "../components/WTAPaywallV2Icons";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { WTAPaywallV2List } from "../components/WTAPaywallV2List";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";
import { WTAPaywallV2Product } from "../../../paywalls.constants";
import { useInView } from "../../../paywalls.utils";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";

type Props = {
  data: {
    label: {
      text: string;
      theme: "blue" | "green" | "yellow" | "purple";
      icon: string;
    };
    title: string;
    items: string[];
    trackingKey?: string;
  };
  sectionOrder: number;
};

const trialItems = [
  "You are getting a free trial of [[trialDays]] days. You won't be charged until your trial ends.",
  "After the trial, you'll be automatically charged <span style='color: #6869F6'>[[price]] every [[period]]</span> unless cancelled.",
  "You can cancel your subscription anytime from your profile.",
  "If you don’t see the expected results after completing different levels, we’ll refund your money in the first 30 days. <a style='text-decoration:underline;color:#6869F6' href='/money-back-policy' target='_blank'>View our money-back policy.</a>"
];

export const WTAPaywallV2PaymentInfo: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { label, title, items, trackingKey } = data;

  const { w2aV2Products, selectedProductKey, scrollToPaymentInfo } = useContainerData('paywalls', CheckoutStore, [
    'w2aV2Products', 'selectedProductKey', 'scrollToPaymentInfo'
  ]);

  const [selectedProduct, setSelectedProduct] = useState<WTAPaywallV2Product>(null);
  const [processedItems, setProcessedItems] = useState<string[]>([]);

  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView(trackingKey || 'payment-info', sectionOrder);
  }, [isInView]);

  useEffect(() => {
    if (items && selectedProduct) {
      const processing = selectedProduct?.product.trialDays > 0 ? trialItems : items;
      const processedItems = processing.map(item => {
        return item
          .replace('[[price]]', `$${selectedProduct?.product.discountedAmount}`)
          .replace('[[trialDays]]', `${selectedProduct?.product.trialDays}`)
          .replace('[[period]]',
            (() => {
              if (selectedProduct?.product.period === 12) {
                return 'year';
              } else if (selectedProduct?.product.period === 1) {
                return 'month';
              } else {
                return `${selectedProduct?.product.period} months`;
              }
            })()
          );
      });
      setProcessedItems(processedItems);
    }
  }, [items, selectedProduct]);

  useEffect(() => {
    setSelectedProduct(w2aV2Products?.find(p => p.product.key === selectedProductKey));
  }, [selectedProductKey]);

  useEffect(() => {
    if (scrollToPaymentInfo && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToPaymentInfo]);

  return <div ref={ref as RefObject<HTMLDivElement>} className="wtapv2-payment-info">
    {label && <WTAPaywallV2Label
      theme={label.theme}
      icon={React.createElement(WTAPaywallIcons[label.icon])}
      text={label.text}
      className="wtapv2-payment-info-label"
    />}
    {title && <WTAPaywallV2Typography
      level="h1"
      text={title}
    />}
    <WTAPaywallV2List
      items={processedItems.map(item => <p dangerouslySetInnerHTML={{ __html: item }}></p>)}
      size="large"
      className="wtapv2-payment-info-list"
    />
  </div>;
};