import React, { FC, RefObject, useEffect } from "react";
import s from "./WTAPaywallV2ProductList.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";
import { PaywallClickTypes, WTAPaywallV2Product, WTAPaywallV2ProductMeta } from "../../../paywalls.constants";
import { WTAPaywallV2ProductCard } from "../components/WTAPaywallV2ProductCard";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";
import { CheckoutService } from "../../../services/checkout.service";
import { WTAPaywallV2Button } from "../components/WTAPaywallV2Button";
import { PaywallsService } from "../../../services/paywalls.service";

type Props = {
  data: {
    title: string;
    products: WTAPaywallV2ProductMeta[];
    selectedProductKey: string;
    type?: 'default' | 'cc';
    trackingKey?: string;
  };
  sectionOrder: number;
};

export const WTAPaywallV2ProductList: FC<Props> = ({ data, sectionOrder }) => {
  const { type, trackingKey } = data;
  const { trackSectionView, trackClick } = Container.take('paywalls', PaywallsTrackingService);
  const { onInteract } = Container.take('paywalls', PaywallsService);
  const { w2aV2Products, selectedProductKey, scrollToPlans } = useContainerData('paywalls', CheckoutStore, [
    'w2aV2Products', 'selectedProductKey', 'scrollToPlans'
  ]);
  const { setSelectedProductKey, scrollToPaymentMethods, openCCCheckout } = Container.take('paywalls', CheckoutService);
  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  const onContinue = () => {
    trackClick(PaywallClickTypes.cta, 'product-list');
    onInteract('product-list');
    if (type === 'cc') {
      openCCCheckout();
    } else {
      scrollToPaymentMethods();
    }
  };

  const onProductClick = (product: WTAPaywallV2Product) => {
    let clickType: PaywallClickTypes;
    switch (product.product.period) {
      case 1:
        clickType = PaywallClickTypes.monthlyBlock;
        break;
      case 3:
        clickType = PaywallClickTypes.quarterlyBlock;
        break;
      case 12:
        clickType = PaywallClickTypes.annualBlock;
        break;
    };
    onInteract(product.product.key);
    trackClick(clickType, product.product.key);
    setSelectedProductKey(product.product.key);
  };

  useEffect(() => {
    if (scrollToPlans && ref.current) {
      const offset = 160;
      const container = document.getElementById('paywalls-container');
      const elementPosition = ref.current.getBoundingClientRect().top + container.scrollTop;
      container.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth'
      });
    }
  }, [scrollToPlans]);

  useEffect(() => {
    isInView && trackSectionView(trackingKey || 'product-list', sectionOrder);
  }, [isInView]);

  useStyles(s);
  return <div className="wtapv2-product-list" ref={ref as RefObject<HTMLDivElement>}>
    <div className="wtapv2-product-list-list">
      {w2aV2Products?.map(product => (
        <WTAPaywallV2ProductCard
          key={product.product.key}
          product={product}
          size="middle"
          selected={selectedProductKey === product.product.key}
          onClick={() => onProductClick(product)}
        />
      ))}
    </div>
    {/* <a onClick={() => trackClick(PaywallClickTypes.moneyBackGuaranteeLink)} href="/money-back-policy" target="_blank" className="wtapv2-product-list-link">
      30-Day Money-Back Guarantee
    </a> */}
    <WTAPaywallV2Button
      text="Get my plan"
      onClick={onContinue}
    />
  </div>;
};
